import '../../App.css'
import {globals} from '../Globals/Globals'

const FooterPage = () => {
    //rendering
    return(
         <div id="footer-container" className="col-12"> 
           <div id="footer-bottom">
             <div id="social-networks">
               <div onClick={()=>{window.open(globals.links.linked_in)}} id='linked-in'/>
               <div onClick={()=>{window.open(globals.links.facebook)}} id='facebook'/>
               <div onClick={()=>{window.open(globals.links.youtube)}} id='youtube'/>
             </div>
             <div className="footer-copyrights">&copy;2022-2024 Eglise Adventiste de Thonon-les-bains</div>
           </div>
         </div>
     )
}

export default FooterPage;