import React, { Component } from 'react';
import MediaRawTableDataSync from '../Components/MediaRawTableDataSync/MediaRawTableDataSync';
import { cGUID } from '../Components/Globals/Globals';

class TerminalView extends Component { 
  constructor(props){
    super(props);
    this.state = {current_media:null, currentSession:cGUID()}/*Default page*/
  }

  render = () =>{
    return (
      <div className="terminal-container">
        <MediaRawTableDataSync
          carousel = {true}
          indicators={this.props.preview?true:false}
          controls={this.props.preview?true:false}
          interval={this.props.interval?this.props.interval:5000}
          current_session={this.state.currentSession}
          />
      </div>
    );
  }
}

export default TerminalView;
