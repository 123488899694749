import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Table, Button, UncontrolledCarousel } from "reactstrap";
import {globals, cTimeStamp, cGUID} from "../Globals/Globals"
import Blob3 from "../Blob3/Blob3";
import {getDatasyncMediaOneBlock, getFormValue3Tiers, _syncDelete} from "../DataSync3/DataSync3"


class MediaRawTableDataSync extends Component{
    constructor(props){
        super(props);
        this.state={media_list:null};
    }

    componentDidMount = async () => {
        //Let us fetch media from remote database   
        if (globals.parameters.debugging) {
            console.log("FilesRawTableDataSync::componentDidMount with session=",this.props.current_session);
        }

        getDatasyncMediaOneBlock({
            session_guid:this.props.current_session,
            date_synchro:'1970-07-10 063050',
            company_guid:globals.datasync.thononadvent_company_guid,
            syncSeekUrl:globals.datasync.syncSeekUrl,
            seek_filter_clause:"%20true%20",
            table_guid:globals.datasync.media_table_guid,
            debugging:false,
            one_block_pull:true,
            form_version:globals.parameters.form_version,
            on_media_count_callback:(available_items)=>{
                console.log("Availabale Items:", available_items)
            },
            on_media_ready_callback:(media_blob_list)=>{
                this.setState({media_list:media_blob_list}, ()=>{if (globals.parameters.debugging) console.log("15:getDatasyncMediaOneBlock has anwsered")})

                /* Prepare Carousel structure */
                if (this.props.carousel){
                //Map to carousel
                    let carouselList = media_blob_list.map((item) => {return {
                        key:item.auto_id, 
                        src:getFormValue3Tiers(item.data_blob.form,"file_data"),
                        altText:'',
                        caption:"", 
                        header:""}});

                        this.setState({carouselList:carouselList})
                }
            },
            on_media_failed_callback:
            (error_message)=>{
                alert(error_message)
            }
        });       
    }
  

    onEditFileClickHandler = async (p_int_index_in_array) => {        
        //Retrieve selected file id from selection index array
        let selected_idfile = this.state.media_list[p_int_index_in_array].idfile;

        //Broadcast edit mode to parent view
        if (this.props.onEditMedia)
                this.props.onEditMedia(selected_idfile)
    }

    onOpenFileClickHandler = async (p_int_index_in_array) => {        
        //Returns selected file object

        //Trigger Open viewer parent view
        if (this.props.onOpenMedia)
                this.props.onOpenMedia(this.state.media_list[p_int_index_in_array])
    }

    onDeleteFileClickHandler = async (mediaItemId) => {
        let l_o_mediaItem = Object.assign({}, this.state.media_list[mediaItemId]); 

        //Rely on low level saveFormToDatasync API
        if (!window.confirm("Souhaitez vous supprimer ce média ?"))return false;

        if (_syncDelete(l_o_mediaItem.data_guid)){
            /* Remove table item from item_list */
            alert("Média supprimé")
            /* Refresh display from caller */
            /* Remove media from media_list */
            this.setState({media_list: this.state.media_list.filter((item, j) => j !== mediaItemId)}, ()=>{console.log("delete mediaItemId=>",mediaItemId)});
        }
        else{
            alert(`Erreur de suppression`)
        }
    }

    fileTableRendering = () => {
        let table_body = null;

        if (!((this.state.media_list != null) && (this.state.media_list.length > 0))){
            table_body = <tr><td colSpan="4">Aucun Fichier</td></tr>;
        }
        else{
            table_body = this.state.media_list.map((fileItem,i)=>{
                return  <tr key={i} className="thumbnail">
                        {/*<td className="col_logo"><FontAwesomeIcon icon={faEllipsisH} onClick={()=>{this.onDeletefile(parseInt(i,10))}} style={{cursor:"pointer"}}/></td>*/}
                        <td className="col_logo"><Button onClick={()=>{this.onDeleteFileClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Effacer le média</Button></td>
                        <td className="col_logo"><Button onClick={()=>{this.onEditFileClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Modifier le média</Button></td>
                        <td className="col_desc"><p>{fileItem.data_blob.title}</p></td>  
                        <td className="col_desc"><p>{fileItem.data_blob.full_file_name}</p></td>  
                        <td className="col_logo">
                                <Blob3
                                    readOnly={true}
                                    class="pic"
                                    Caption="Charger le média ..." 
                                    data={getFormValue3Tiers(fileItem.data_blob.form,"file_data")}/>
                        </td>
                        <td className="col_desc"><p>{fileItem.data_blob.time_stamp}</p></td>  
                        <td className="col_desc"><p>{fileItem.data_blob.is_enabled=="1"?"Affiché":"Masqué"}</p></td>  
                    </tr>});
                }

        return(
            <Table striped responsive={true}>
                <thead>
                    <tr>
                        <th>Effacer</th>
                        <th>Modifier</th>
                        <th>Titre</th>
                        <th>fichier</th>
                        <th>Preview</th>
                        <th>Date</th>
                        <th>Visible</th>
                    </tr>
                </thead>
                <tbody>
                    {table_body}
                </tbody>
            </Table>
            );
    }

    fileGridRendering = () => {
        if (((this.state.media_list != null) && (this.state.media_list.length > 0) && (this.props.grid) ))
        return(
            <div className="events-columns-container">
                {
                    this.state.media_list.map((fileItem,i)=>{
                        return  <div key={i} className="event-column" onClick={()=>{this.onOpenFileClickHandler(parseInt(i,10))}}>
                                <p>{fileItem.data_blob.title}</p>
                                        <Blob3
                                            readOnly={true}
                                            class="pic"
                                            Caption="Charger le média ..." 
                                            data={getFormValue3Tiers(fileItem.data_blob.form,"file_data")}/>
                                
                                <p>{fileItem.data_blob.time_stamp}</p>
                            </div>})
                }
            </div>);
    }

    carouselRendering = () => {
        if ((this.state.carouselList != null) && (this.state.carouselList.length > 0) && (this.props.carousel)) 
        return(
            <div className={`${this.props.className}`}>
                <UncontrolledCarousel 
                    items={this.state.carouselList} 
                    className="actions"
                    nextLabel={"Suivant"}
                    prevLabel={"Précédent"}
                    wrap={true}
                    fade={true}
                    pause={false}
                    variant={"dark"}
                    indicators={this.props.indicators}
                    controls={this.props.controls}
                    interval = {this.props.interval}/>
            </div>);
    }

    render(){
        return (
            <>
                {this.props.grid  && <>{this.fileGridRendering()}</>}
                {this.props.table && <>{this.fileTableRendering()}</>}
                {this.props.carousel && <>{this.carouselRendering()}</>}
            </>);
    }
}


MediaRawTableDataSync.propTypes = { 
    grid: PropTypes.bool,
    table: PropTypes.bool,
    carousel: PropTypes.bool
};

MediaRawTableDataSync.defaultProps = {
    grid:false,
    table:false,
    carousel:false
};



export default MediaRawTableDataSync;
