/*
DailyWorshipView
*/

import React, {Component, useEffect, useState} from 'react';
import { Button } from 'reactstrap'
import SocialShare from '../Components/SocialShare/SocialShare';
import {globals} from '../Components/Globals/Globals'
import DailyWorshipText from '../Components/DailyWorshipText/DailyWorshipText';
import NestedTitlePicture from '../Components/NestedTitlePicture/NestedTitlePicture';
import WorshipPicture from '../assets/daily/ellen_white_meditation.jpeg';

let title_color = "white"
let title_font_size = "3em"
let container_width = "100%"
let container_height = "350px"

const DailyWorshipView = () => {
    //rendering
    return (
        <div className="view-container worship-view">
            {/* Full width main picture */}
            <div>
                    <NestedTitlePicture 
                            width={container_width}
                            height={container_height}
                            pictureTitle = {"Ellen G.White"}
                            pictureURL={WorshipPicture}
                            color={title_color}
                            fontSize={title_font_size}
                            />    {globals.menu.daily_worship}
            </div>

            
            <div className="body-page-container">

                {/*---- Worship Title ----*/}
                <div className='daily-worship-container'>
                    <DailyWorshipText/>
                </div>
                
               
                <div className="buttons-panel">
                    <p>Vous êtes touchés, par la médiation du jour&nbsp;? Partagez là&nbsp;!</p>
                    <SocialShare 
                        url={globals.share.worship.url}
                        description={globals.share.worship_description}
                        />
                </div>    
                
                
                {false && <div className="introduction-container">
                    <p>
                    Vous désirez recevoir chaque jour une prière, abonnez vous...
                    </p>
                        <a class="rounded-button classic-button" href="/contact">Je m'abonne...</a>
                </div>}   
                
            </div>    
        </div>
        );
}

export default DailyWorshipView;