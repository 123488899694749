import React from 'react';
import axios from "axios";
import {globals} from '../Globals/Globals';

class MediaUpload extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {file: this.props.data?this.props.data:'', msg: '', uploaded:this.props.data?true:false};
	}
	
	onFileChange = (event) => {
		this.setState({
			file: event.target.files[0]
		},()=>{console.log("file->", this.state.file.name)});
	}
	
	uploadFileData = (event) => {
		event.preventDefault();
		this.setState({msg: ''});
		console.log("Loading started ...")
		let data = new FormData();
		data.append('file', this.state.file);

        //Try with axios
        //axios.post("http://localhost:4000/upload", data, { // receive two parameter endpoint url ,form data 
        axios.post(`${globals.services.event_upload}`, data, { // receive two parameter endpoint url ,form data 
    })
    .then(res => { // then print response status
      console.log(res.statusText)
    }).then(response => {
		console.log("Media successfully loaded !");
        this.setState({msg: "Fichier chargé avec succès"}, ()=>{
			if (this.props.uploadMedia) this.props.uploadMedia(this.state.file)
		});
    }).catch(err => {
        this.setState({error: err});
    });

    {/** 
        //Try witth fetch
		fetch('http://localhost:4000/upload', {
			method: 'POST',
			body: data
		}).then(response => {
			this.setState({msg: "File successfully uploaded"});
		}).catch(err => {
			this.setState({error: err});
		});
*/}
	}
	
	render() {
		return (
			<div className='media-upload-container'>
				<p>{this.state.msg}</p>
				<input type="text" value={this.props.data}/>
				<input onChange={this.onFileChange} type="file"></input>
				<button disabled={!this.state.file} onClick={this.uploadFileData}>Upload</button>
			</div>
		)
	}

}

export default MediaUpload;