import React, {Component} from "react";
import { globals } from "../Globals/Globals";
import {send_custom_email} from '../MailSync/MailSync';
//import DynamicForm from "../DynamicForm/DynamicForm";
import DynamicForm from "../DynamicForm3Tiers/DynamicForm3Tiers";
import {saveFormToDatasync, getFormValue3Tiers, getFormSelectedValues} from '../DataSync3/DataSync3'
import contact_request_template from "../../assets/templates/mailing/contact-request.txt"
import contact_request_acknowledge_template from "../../assets/templates/mailing/contact-request-acknowledge.txt"
import './ContactForm.css'

class ContactForm extends Component{
    constructor(props){
        super(props)

        /** Mails template files */
        fetch(contact_request_template)
        .then(response => response.text())
        .then(contact_request_template => this.setState({ contact_request_template: contact_request_template },()=>{console.log("contact-request.txt -->", this.state.contact_request_template)}))
        
        /** Mails template files */
        fetch(contact_request_acknowledge_template)
        .then(response => response.text())
        .then(contact_request_acknowledge_template => this.setState({ contact_request_acknowledge_template: contact_request_acknowledge_template },()=>{console.log("contact-request-acknowledge.txt -->", this.state.contact_request_acknowledge_template)}))

        /** Fields structure declaration */
        this.contact_form = {
            version : globals.parameters.form_version,
            Rows : [{
            Cols : [{
                Fields : [
                {
                name : "last_name",
                title : "Nom de famille",
                placeholder : "Nom de famille",
                input_type : "text",
                min_length : -1,
                max_length : 20,
                required  : false,
                },
                {
                name : "first_name",
                placeholder : "Prenom",
                input_type : "text",
                min_length : -1,
                max_length : 20,
                required  : false,
                },
                {
                name : "e_mail",
                title : "Adresse électronique",
                placeholder : "courriel",
                input_type : "email",
                min_length : 5,
                max_length : 40,
                required  : true,
                },
                {
                name : "contact_message",
                placeholder : "Ecrivez nous...",
                title : "Votre message",
                input_type : "memo",
                min_length : -1,
                max_length : -1,
                required  : true,
                },
                {
                name : "human_captcha",
                placeholder : "Je ne suis pas un robot",
                title : "Votre message",
                input_type : "captcha",
                min_length : -1,
                max_length : -1,
                required  : false,
                }
                ]
            }]
            }]
          }

    /* OBSOLETE
        this.clearObject = {
            nom:"",
            prenom:"",
            email:"",
            message:"",
            err_nom:[], err_prenom:[], err_email:[], err_message:[], err_captcha:[],
            has_changed:false,
            submitted:false,
            captcha:""
        }

        this.state = this.clearObject;*/
    }

  broadcastEmailConfirmation = (saved_contact_form) =>{
      //Mail free Bible request to Thonon Advent staff
      let l_sCivility_without_empty = getFormSelectedValues(saved_contact_form.data_blob,"civility")[0]=="<"?"":getFormSelectedValues(saved_contact_form.data_blob,"civility")

      if (send_custom_email(
        {
          from:globals.mailer.ne_pas_repondre_email,//From - ne pas repondre
          email:globals.mailer.thonon_advent_staff_mailing_list,/* OVH ThononAdvent mailing group */
          subject:globals.mailer.contact_request_mail_subject,
          prenom:`${getFormValue3Tiers(saved_contact_form.data_blob,"first_name")}`,
          nom:`${getFormValue3Tiers(saved_contact_form.data_blob,"last_name")}`,
          civility:l_sCivility_without_empty,
          last_name: getFormValue3Tiers(saved_contact_form.data_blob,"last_name"),
          first_name: getFormValue3Tiers(saved_contact_form.data_blob,"first_name"),
          e_mail: getFormValue3Tiers(saved_contact_form.data_blob,"e_mail"),
          contact_message: getFormValue3Tiers(saved_contact_form.data_blob,"contact_message"),
          signature:globals.mailer.signature,
          template:this.state.contact_request_template,
          token:""
        }
      )){
          console.log("email sent to Thonon Advent Staff");

            //Mail confirmation back to user
            if (send_custom_email(
                {
                from:globals.mailer.ne_pas_repondre_email,
                email:getFormValue3Tiers(saved_contact_form.data_blob,"e_mail"),
                subject:globals.mailer.bible_request_mail_acknowledge_subject,
                civility:l_sCivility_without_empty,
                prenom:getFormValue3Tiers(saved_contact_form.data_blob,"first_name"),
                nom:getFormValue3Tiers(saved_contact_form.data_blob,"last_name"),
                signature:globals.mailer.signature,
                message:globals.mailer.contact_request_mail_acknowledge_message,
                template:this.state.contact_request_acknowledge_template,
                token:""
                }
            )){
                //alert(`${getFormSelectedValues(saved_contact_form.data_blob,"civility")} ${getFormValue3Tiers(saved_contact_form.data_blob,"last_name")} ${getFormValue3Tiers(saved_contact_form.data_blob,"first_name")} vous allez recevoir un courriel de confirmation de votre demande de contact à l'adresse ${getFormValue3Tiers(saved_contact_form.data_blob,"e_mail")}`)
                alert(`${l_sCivility_without_empty} ${getFormValue3Tiers(saved_contact_form.data_blob,"last_name")} ${getFormValue3Tiers(saved_contact_form.data_blob,"first_name")} vous allez recevoir un courriel de confirmation de votre demande de contact à l'adresse ${getFormValue3Tiers(saved_contact_form.data_blob,"e_mail")}`)
            }
            else{
                alert("Echec d'envoi du courriel de confirmation")
            }
      }
      else{
        alert("Echec d'envoi du courriel de confirmation")
      }
  }
    
    handle_captcha = e => {
        e.preventDefault();

        this.setState({captcha:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    randomize = (min, max) => {
        return Math.floor(min + Math.random() * (max - min));
    }
    
    contact_form_saved_handler = blob_data => {
        if (globals.parameters.debugging){
            console.log("contact_form_saved_handler => ", blob_data)
            alert("contact_form_saved_handler => Save succedeed")
        }

        //Mail confirmation
        this.broadcastEmailConfirmation(blob_data);
    }

    contact_form_failed_handler = err => {
        if (globals.parameters.debugging)
            console.error("contact_form_failed_handler => save failed with this error" + err)

        if (globals.parameters.save_failed_alert)
            alert(err)
    }

    render = () => {
        return(
            <DynamicForm
                form = {this.contact_form}
                company_guid = {globals.datasync.thononadvent_company_guid}
                table_guid= {globals.datasync.contact_request_table_guid}
                clearOnSave={true}
                clearOnUpdate={true}
                onFormSaved = {this.contact_form_saved_handler}
                onFormFailed = {this.contact_form_failed_handler}
              />
            )
    }
}

export default ContactForm;