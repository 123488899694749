import React, { Component } from "react";
// react plugin that creates an input with badges
//import TagsInput from "react-tagsinput";
import {globals ,cTimeStamp, wScrollTo, randomize} from '../Globals/Globals'
import Blob from "../Blob/Blob";
import Multiselect from 'multiselect-react-dropdown';
import {saveFormToDatasync} from '../DataSync3/DataSync3.jsx'
import "./DynamicForm.css"

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroup as InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


class DynamicForm extends Component {
  constructor (props){
    super(props);

    this.state = {form:{}}
  }
  
  componentDidMount = () => {
    //Component initialization in edit mode
    if (this.props.form) {
      this.clearForm();
    }
    //this.state = {fieldError:[], form:{}}
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.form !== prevProps.form) {
        //Lookup field props has changed
        if (globals.parameters.debugging)
          console.log("componentDidUpdate -> has changed", this.props.form)
        this.clearForm()
    }
  }

  clearForm = () => {
    /** Duplicate props.form */
    let clearObject2 = JSON.parse(JSON.stringify(this.props.form));
    let l_user_guid = null
  
    
/*
    if (l_user_guid == null ){
      alert("Vous devez vous reconnecter")
      Redirect.toString("/")
    }
*/
    /** Override user-guid wit a given props */
    clearObject2.user_guid = l_user_guid

    if (globals.parameters.debugging){
      console.log("----- clearForm -----")
      console.log("clearForm::this.props.form->",this.props.form)
      console.log("clearForm::clearObject2->",clearObject2)
    }

    this.setState({fieldError:[],form:{},captcha1:randomize(0,5), captcha2:randomize(0,5)}, //Clear forced
      ()=>{
        if (globals.parameters.debugging) console.log("DynamicForm state cleansed 2:", this.state)
            this.setState({fieldError:[],form:clearObject2})
          });
  }

  /**
   * 
   * @param {*} pFieldObject 
   * @param {*} value 
   * Purpose : compute a special tag to facilitate SQL requests 
   */
  setFieldTag = (pFieldObject, value) => {
    pFieldObject.tag = `${pFieldObject.name}${value}`;
    if (false && globals.parameters.debugging) console.log(`pFieldObject.tag => ${pFieldObject.tag}`)
  }

  setFieldData = (pFieldObject, value) => {
    pFieldObject.value = value;
    this.setFieldTag(pFieldObject, value)
  }

  getSelectedFieldValues = (pFieldObject) => {
    return pFieldObject.selected_values?pFieldObject.selected_values:"";
  }

  setSelectedFieldValues = (pFieldObject, selectedValues) => {
    pFieldObject.selected_values = selectedValues;
    this.setFieldTag(pFieldObject, selectedValues)
  }
  
  getFieldData = (pFieldObject) => {
    /** Return data value form field object */
    return pFieldObject.value?pFieldObject.value:"";
  }
  
  setFieldError = (pFieldObject, value) => {
    try{
      pFieldObject.err = value;
      let nextFieldError = this.state.fieldError;
      nextFieldError[pFieldObject.name] = value
      this.setState({ fieldError : nextFieldError})
      //Scroll form to first erroneous field
      wScrollTo(pFieldObject.name)
    }
    catch(e){
      console.log(`Error caught on ${e}`)
    }
  }

  getFieldError = (pFieldObject) => {
    /** Return data value form field object */
    try {
      return pFieldObject.err?pFieldObject.err:"";
    }
    catch(e){
      return("")
    }
  }

  _error_label = field => {
    return(
      <label className="dynamic-form-error">
            {this.getfieldErrorLabel(field)} 
      </label>
    )
  }

  getfieldErrorLabel = (pFieldObject) => {
    return this.getFieldError(pFieldObject)
  }

  getFieldLabelTitle = (pFieldObject) => {
    return(
      <h6 id={pFieldObject.name}>
        {pFieldObject.title?pFieldObject.title:pFieldObject.placeholder}
        {(pFieldObject.required && !this.props.read_only) && <span className="icon-danger">*</span>}
      </h6>
    )
  }

  getCaptchaFieldLabelTitle = (pFieldObject) => {
    return(
      <h6 id={pFieldObject.name}>
        {`${this.state.captcha1} + ${this.state.captcha2}`}
        {pFieldObject.required && <span className="icon-danger">*</span>}
      </h6>
    )
  }

  getFielValue = (pFieldObject) => {
    return(
      pFieldObject.value?pFieldObject.value:(pFieldObject.default_value?pFieldObject.default_value:"")
    )
  }

  getFieldPrompt = (pFieldObject) => {
    return(
        pFieldObject.placeholder?pFieldObject.placeholder:pFieldObject.title
    )
  }

  _numeric_field_with_add_on = (field,fa_symbol) => {
    return(
      <>
        {this.getFieldLabelTitle(field)}
        <InputGroup className="border-input">
          <Input
            readOnly={this.props.read_only?this.props.read_only:false} 
            type={field.input_type} 
            value={this.getFielValue(field)} 
            placeholder={field.placeholder}
            onChange={(e)=>{
                  e.preventDefault();
                  this.dynamicInputNumericChangeHandler({event:e, fieldObject:field})}}
            />

          {/** Euro symbol */}

          {fa_symbol && 
          <InputGroupAddon addonType="append">            
            <InputGroupText>
              <i className={`fa ${fa_symbol}`}/>
            </InputGroupText>
          </InputGroupAddon>}
          
        </InputGroup>
        {this._error_label(field)}
      </>
    )
  }

  _captcha_field = (field,fa_symbol) => {
    return(
      <>
        {this.getCaptchaFieldLabelTitle(field)}
        <InputGroup className="border-input">
          <Input
            readOnly={this.props.read_only?this.props.read_only:false} 
            type={field.input_type} 
            value={this.getFielValue(field)} 
            placeholder={field.placeholder}
            onChange={(e)=>{
                  e.preventDefault();
                  this.dynamicInputNumericChangeHandler({event:e, fieldObject:field})}}
            />
        </InputGroup>
        {this._error_label(field)}
      </>
    )
  }

  _text_field = field => {
    return(
      <div>
        {this.getFieldLabelTitle(field)}
        <Input
          readOnly={this.props.read_only?this.props.read_only:false}  
          type={field.input_type}
          value={this.getFielValue(field)} 
          placeholder={field.placeholder}
          onChange={(e)=>{
                e.preventDefault();
                this.dynamicInputTextChangeHandler({event:e, fieldObject:field})}}
          />
          {this._error_label(field)}
      </div>)
  }

  _email_field = field => {
    return(
      <div>
        {this.getFieldLabelTitle(field)}
        <Input
          readOnly={this.props.read_only?this.props.read_only:false}  
          type={field.input_type}
          value={this.getFielValue(field)} 
          placeholder={field.placeholder}
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          onChange={(e)=>{
                e.preventDefault();
                this.dynamicInputTextChangeHandler({event:e, fieldObject:field})}}
          />
          {this._error_label(field)}
      </div>)
  }

  _memo_field = field => {
    return(
      <div>
        {this.getFieldLabelTitle(field)}
        <textarea
          className="form-control"
          readOnly={this.props.read_only?this.props.read_only:false} 
          type={field.input_type}
          value={this.getFielValue(field)} 
          placeholder={field.placeholder}
          rows={field.rows}
          onChange={(e)=>{
                e.preventDefault();
                this.dynamicInputTextChangeHandler({event:e, fieldObject:field})}}
          />
          {this._error_label(field)}
      </div>)
  }

  _combo_field = field => {
    return(
      <div>
        {this.getFieldLabelTitle(field)}    
          <Multiselect
              showArrow
              options={field.combo_list}
              isObject={false}
              displayValue="key"
              selectedValues={field.selected_values?field.selected_values.split(";"):[]}
              placeholder= {field.placeholder}
              emptyRecordMsg = ""
              onSelect = {(selectedList, selectedItem) => {this.setSelectedFieldValues(field, selectedList.join(";")); if (globals.parameters.debugging) console.log("field.selected_values->", field.selected_values)}}
              onRemove = {(selectedList, selectedItem) => {this.setSelectedFieldValues(field, selectedList.join(";")); if (globals.parameters.debugging) console.log("field.selected_values->", field.selected_values)}}
              disable = {this.props.read_only}
              singleSelect = {true}
              />
          {this._error_label(field)}
      </div>)
  }

  _multi_field = field => {
    return(
      <div>
        {this.getFieldLabelTitle(field)}    
          <Multiselect
              showArrow
              options={field.combo_list}
              isObject={false}
              displayValue="key"
              selectedValues={field.selected_values?field.selected_values.split(";"):[]}
              placeholder= {field.placeholder}
              emptyRecordMsg = ""
              onSelect = {(selectedList, selectedItem) => {this.setSelectedFieldValues(field, selectedList.join(";")); if (globals.parameters.debugging) console.log("field.selected_values->", field.selected_values)}}
              onRemove = {(selectedList, selectedItem) => {this.setSelectedFieldValues(field, selectedList.join(";")); if (globals.parameters.debugging) console.log("field.selected_values->", field.selected_values)}}
              disable = {this.props.read_only}
              singleSelect = {false}
              />
          {this._error_label(field)}
      </div>)
  }

  saveFormToDatasyncProcess = () => {
    //Everything sounds ok in Form, Go ahead
    let hasDataGuid = (this.props.datasync_object && this.props.datasync_object.data_guid)
    saveFormToDatasync (
      hasDataGuid?this.props.datasync_object.data_guid:null, //data_guid
      this.state.form, //p_o_form,
      this.props.company_guid,//p_s_company_guid,
      this.props.table_guid,//p_s_table_guid,
      hasDataGuid?this.props.datasync_object.createstamp:cTimeStamp(), //p_dt_createstamp,
      hasDataGuid?cTimeStamp():null,//p_dt_updatestamp,
      null,//p_dt_deletestamp,
      this.onFormSavedLocalHandler,//on_save_handler(p_s_data_blob, p_extra_data)
            this.onFormUpdatedLocalHandler,//on_update_handler(p_s_data_blob, p_extra_data)
            this.onFormFailedLocalHandler,//on_failed_handler(p_error_message, p_extra_data)
      null//p_extra_data
      )
  }

  /** Form Handlers */
  onClickSubmitFormHandler  = async event => {
    if (event)
      event.preventDefault();

    //Force all fields check    
    let canSubmit = true

    let iRow = 0;
    while(
      (iRow < this.state.form.Rows.length)
      && (canSubmit)){
      let iCol = 0;
      while ((iCol < this.state.form.Rows[iRow].Cols.length) && canSubmit){
        let ii=0
        while(
          (ii < this.state.form.Rows[iRow].Cols[iCol].Fields.length)
          && (canSubmit &= this.checkValidation(this.state.form.Rows[iRow].Cols[iCol].Fields[ii]))){

          if (globals.parameters.debugging)
            console.log(`Fields[${ii}]|${this.state.form.Rows[iRow].Cols[iCol].Fields[ii].name}| canSubmit=${canSubmit}`)
          
            ii++;
        }
        iCol++
      }
      iRow++;
    } 
          
    if (globals.parameters.debugging)
        console.log("canSubmit:", canSubmit)

    if (!canSubmit) {
      let err_message = "Le formulaire comporte des erreurs !"
      if (this.props.onFormFailed)
        this.props.onFormFailed(err_message)
      else 
        alert(`${err_message}`)
      return false;
    }
   
    //Invoke onFormSubmit props
   
    if (this.props.onFormSubmit){
      if (globals.parameters.debugging)
        alert("onFormSubmit => filter log with AsyncDebug:: prefixe")
      //If props function return false then cancel form submit and do not save !
      this.props.onFormSubmit(this.state.form,this.saveFormToDatasyncProcess)
    }
    else {
      //Call save anyway
      this.saveFormToDatasyncProcess()
    }



    //this.saveFormToDatasync_OLD((this.props.datasync_object && this.props.datasync_object.data_guid)?this.props.datasync_object.data_guid:null);
  }

  onFormSavedLocalHandler = (blob) => {
    if (this.props.onFormSaved) 
      this.props.onFormSaved(blob)
    else 
      console.error("DynamicForm.onFormSaved props is not defined !")
    //clear form
    if (this.props.clearOnSave) this.clearForm()
    //Call onTerminated if set by user
    if (this.props.onTerminated) this.props.onTerminated()
  }

  onFormUpdatedLocalHandler = (blob) => {
    if (this.props.onFormUpdated) 
      this.props.onFormUpdated(blob)
    else 
      console.error("DynamicForm.onFormUpdated props is not defined !")
    //clear form
    if (this.props.clearOnUpdate) this.clearForm()
    //Call onTerminated if set by user
    if (this.props.onTerminated) this.props.onTerminated()
  }

  onFormFailedLocalHandler = (err) => {
    if (this.props.onFormFailed) 
      this.props.onFormFailed(err)
    else {
      console.error("DynamicForm.onFormFailed props is not defined !")
      alert("Erreur de sauvegarde !!!")
    }    
  }

  checkValidation = (pFieldObject) => {
    let fieldName = pFieldObject.name;

    /** Get field properties */
    let min = pFieldObject.min_length
    let max = pFieldObject.max_length
    let fieldValue = this.getFieldData(pFieldObject);
    let selectedFieldValues = this.getSelectedFieldValues(pFieldObject);
    
    let errorsFieldName = `err_${fieldName}` /** Error data displayed in dedicated error label, beside input field in Form */
    let nextErrors = [];

    if (globals.parameters.debugging)
      console.log(`min:${min} - max:${max}`)

    /** Check basic field validity except combo and radio */
    if ((pFieldObject.input_type.toLowerCase() != "combo") && (pFieldObject.input_type.toLowerCase() != "radio")){
      if ((pFieldObject.required) && (fieldValue.trim().length <= 0))
        nextErrors.push(`obligatoire`)
    }
    
    if ((pFieldObject.input_type.toLowerCase() == "combo") || (pFieldObject.input_type.toLowerCase() == "radio")){
      if ((pFieldObject.required) && (selectedFieldValues.trim().length <= 0))
        nextErrors.push(`obligatoire`)
    }

    if ((min > 0) && (fieldValue.trim().length < min))
        nextErrors.push(`trop court.`)

    //Captcha check
    if (pFieldObject.input_type.toLowerCase() == "captcha"){
      if (parseInt(fieldValue) !== (this.state.captcha1 + this.state.captcha2))
                    nextErrors.push(`calcul faux !`)
    }

    //Email check
    if (pFieldObject.input_type.toLowerCase() == "email"){
      if (!fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
                    nextErrors.push(`Format de mail incorrect !`)
    }

    if ((max > 0) && (fieldValue.trim().length > max))
        nextErrors.push(`trop long, ${fieldValue.trim().length - max} caractères en trop.`)
    /* Special validation handlers
    if (parseInt(this.state[fieldName]) !== (this.state.v1 + this.state.v2))
        nextErrors.push(`calcul faux !`)*/

    if (globals.parameters.debugging){
        console.log(`-----------------`)
        console.log(`fieldName => ${fieldName}`)
        console.log(`value => ${fieldValue}`)

        console.log(`errorsFieldName => ${errorsFieldName}`)
        console.log(`nextErrors => ${nextErrors}`)
        console.log(`nextErrors.length => ${nextErrors.length}`)
    }

    //update error field
      if (globals.parameters.debugging){
        console.log("nextFieldsErrorsArray=>",nextErrors)
      }

      this.setFieldError(pFieldObject, nextErrors.join("/"))

      if (globals.parameters.debugging){
        console.log(`new fieldError : ${this.getFieldError(pFieldObject)}`)
      }    

    //set change flag
    //nextState.has_changed = true

    //Return validation predicate
    return (nextErrors.length === 0) //returns true if no error occurs
  }

  dynamicInputTextChangeHandler = (eventObject) => {
      if (eventObject && eventObject.event)
          eventObject.event.preventDefault();
      if (globals.parameters.debugging)
          console.log(`#${eventObject.fieldObject.name} Input field has changed`)
      
      this.setFieldData(eventObject.fieldObject, eventObject.event.target.value)

      //Validate field
      if (globals.parameters.debugging)
        console.log(`#${eventObject.fieldObject.name} => ${this.getFieldData(eventObject.fieldObject)}`)

      this.checkValidation(eventObject.fieldObject)
  }

  dynamicInputNumericChangeHandler = (eventObject) => {
      if (eventObject && eventObject.event)
          eventObject.event.preventDefault();
      if (globals.parameters.debugging)
          console.log(`#${eventObject.fieldObject.name} Input field has changed`)
      
      if (
          eventObject.event.target.value.length == 0 ||
          !isNaN(eventObject.event.target.value) && 
          !isNaN(parseFloat(eventObject.event.target.value))) 
        this.setFieldData(eventObject.fieldObject, eventObject.event.target.value)
      else{
        if (globals.parameters.debugging)
          console.log(`Value rejected -> ${eventObject.event.target.value}`)
      }

      //Validate field
      if (globals.parameters.debugging)
        console.log(`#${eventObject.fieldObject.name} => ${this.getFieldData(eventObject.fieldObject)}`)

      this.checkValidation(eventObject.fieldObject)
  }

  _colRendering = col => {
    try{
    return(
      <Col>
      {
        col.Fields.map(
          (field,ii)=>{
            return this._fieldRendering(field, ii)
          }
        )
      }
      </Col>
    )}
    catch(err){
      return(<Col>Error {err.message}</Col>)
    }
  }

  _rowRendering = row => {
    try{
     return(
      <Row>
      {
      row.Cols.map(
        (col,ii)=>{
          return(this._colRendering(col))
        })
      }
      </Row>
     )}
     catch(err){
      return(<Col>Error {err.message}</Col>)
    }
  }

  _fieldRendering = (field, ii) => {
    //Do not display field if empty in read only mode
    if (this.props.read_only &&
        ((field.value == null) || (!field.value) || (field.value.trim().length == 0))
        && ((field.selected_values == null) || (!field.selected_values) || (field.selected_values.trim().length == 0)))
    return (<></>)

    //Do not display hidden field
    if (field.hidden && !this.props.show_hidden)
      return (<></>)

    switch (field.input_type.toLowerCase()){
      /** Dynamic fields */
      case "checkbox-DISABLED" :
        return(
          <FormGroup check>
            {/*-- set anchor ID here --*/}
            <Label check>
              <Input defaultValue="" type="checkbox" readOnly={this.props.read_only?this.props.read_only:false}/>
              {field.placeholder}
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        );

      case "checkbox" :
          /* Checkbox is override with combobox */
        return(
          <div>
            {this.getFieldLabelTitle(field)}    
              <Multiselect
                  showArrow
                  options={["Oui","Non"]}
                  isObject={false}
                  displayValue="key"
                  selectedValues={field.selected_values?field.selected_values.split(";"):[]}
                  placeholder= {field.placeholder}
                  emptyRecordMsg = ""
                  onSelect = {(selectedList, selectedItem) => {this.setSelectedFieldValues(field, selectedList.join(";")); if (globals.parameters.debugging) console.log("field.selected_values->", field.selected_values)}}
                  onRemove = {(selectedList, selectedItem) => {this.setSelectedFieldValues(field, selectedList.join(";")); if (globals.parameters.debugging) console.log("field.selected_values->", field.selected_values)}}
                  disable = {this.props.read_only}
                  singleSelect = {true}
                  />
              {this._error_label(field)}
          </div>)
              
      case "blob":
        return(
          <>
              {this.getFieldLabelTitle(field)}
              <label className="col-md-12 col-form-label">{`${this.getFieldPrompt(field)}:`}</label>
              <div className="col-md-10">
                  <Blob
                      readOnly={this.props.read_only?this.props.read_only:false}
                      Caption={`${this.getFieldPrompt(field)} ...`} 
                      data={field.value} 
                      uploadPicture={(UploadFile) => {field.value = UploadFile.data; this.checkValidation(field)}} 
                      pictureStyle="pic"
                      buttonStyle = {"btn btn-secondary"}/>

                  {/* Sticky error label */}
                  {this._error_label(field)}
              </div>
          </>
        )

      case "email":
        return this._email_field(field);

      case "text":
        return this._text_field(field);

      case "memo":
        return this._memo_field(field);

      case "combo":
      case "radio":
        return this._combo_field(field);

      case "multi":
        return this._multi_field(field);

      case "numeric":
        return this._numeric_field_with_add_on(field,"*");

      case "amount":
        return this._numeric_field_with_add_on(field,"fa-euro");

      case "percent":
        return this._numeric_field_with_add_on(field,"%");
      
      case "captcha":
        return this._captcha_field(field);

      default:
          console.log("alert à Malibu");
    }
  }

  render = () => {
    return (
      <>
              {!this.state.form &&
              <div>
                <h1>Form loading...</h1>
              </div>}
              <div>
                {
                  this.state.form &&
                  this.state.form.Rows &&
                  this.state.form.Rows.map(
                    row => {
                      return(
                        this._rowRendering(row)
                      )
                    })
                }
              </div>
              <Row className="buttons-row">
                  {!this.props.read_only &&
                  <Col md="4" sm="4">
                    <Button
                      block
                      className="btn-round"
                      color="danger"
                      outline
                      type="reset"
                      onClick = {()=>{this.clearForm()}}
                    >
                      Annuler
                    </Button>
                  </Col>}

                  {!this.props.read_only &&
                  <Col md="4" sm="4">
                    <Button
                      block
                      className="btn-round"
                      color="primary"
                      outline
                      type="submit"
                      onClick={(e) => {this.onClickSubmitFormHandler()}}
                    >
                      Soumettre
                    </Button>

                    {/** Sticky button */}
                    {(this.props.sticky_button && !this.props.read_only) &&
                    <Button
                      block
                      className="btn-sticky"
                      color="primary"
                      outline
                      type="submit"
                      onClick={(e) => {this.onClickSubmitFormHandler()}}
                    >
                      Ok
                    </Button>}
                    {/** sticky */}
                  </Col>}
                  
                  {this.props.custom_button_caption &&
                  <Col md="4" sm="4">
                    <Button
                      block
                      className="btn-round"
                      color="primary"
                      type="submit"
                      onClick = {(e) => {if (this.props.custom_button_handler) this.props.custom_button_handler(this.state.form); else console.error("custom_button_handler unset !")}}
                    >
                      {this.props.custom_button_caption}
                    </Button>
                  </Col>}

                  {this.props.custom_button_caption2 &&
                  <Col md="4" sm="4">
                    <Button
                      block
                      className="btn-round"
                      color="primary"
                      type="submit"
                      onClick = {(e) => {if (this.props.custom_button_handler2) this.props.custom_button_handler2(this.state.form); else console.error("custom_button_handler2 unset !")}}
                    >
                      {this.props.custom_button_caption2}
                    </Button>
                  </Col>}
                </Row>
      </>
    );
  }
}
export default DynamicForm;
