/*
AuthentView
*/

import React, { useEffect } from 'react';
import { Navigate, useNavigate  } from "react-router-dom";
import { globals, removeCookies, setCookies, adminConnected, getCookies } from '../Components/Globals/Globals';
import AdminAuthent from '../Components/AdminAuthent/AdminAuthent';
import { Button} from 'reactstrap';

const AuthentView = () => {
    let navigate = useNavigate()
    let connected_user = getCookies()
    {/**
    useEffect(()=>{
        if (adminConnected()){
            navigate("/")
        }
    })
     */}

    const onAdminConnectHandler = (user) => {
        if (globals.parameters.debugging){
            console.log("onAdminConnectHandler user:", user);
        }
        
        //No needs to redirect admin user, just set cookies 
        setCookies(user)
        navigate("/authent")
    }
    
    //rendering
    return (
        <div className="view-container">
            {/* Full width main picture */}
                <div className="body-page-container">
                    {!adminConnected() && <>
                        <h4>Connexion</h4>
                        {/* Laïus introduction de la page*/}
                        <div className="introduction-container">
                            <p>Veuillez entrer vos informations, afin de vous authentifier et accèder à la console d'Administration.
                            <br/>Authentifiez-vous&nbsp;!</p>
                        </div>
                        
                        <AdminAuthent 
                            onAdminGranted={(record)=>{onAdminConnectHandler(record);}}
                            onPartnerGranted={null}/>
                    </>}

                    {adminConnected() && <div>
                        {/* Welcome Panel */}
                        <h4>Administrateur authentifié</h4>
                        <p>
                            Bonjour <b>{connected_user.first_name} {connected_user.name}</b> Vous êtes désormais connecté(e) en administrateur(/trice).<br/>
                            Vous avez accès à la console d'administration des évènements et de la borne d'affichage de la vitrine.<br/>
                            <i>Le webmestre.</i>
                        </p>
                    </div>}

                    {globals.parameters.cookie_debugging &&
                    <div>
                        <br/>
                        <Button onClick={()=>{setCookies({name:"Mab", first_name:"Pat", admin:"1", enabled:1}); navigate("/authent")}}>setCookie debugging</Button>
                        <br/>
                        <Button onClick={()=>{removeCookies(); navigate("/authent")}}>removeCookies debugging</Button>
                    </div>}

            </div>
        </div>
        );
}

export default AuthentView;