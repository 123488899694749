import React, { Component } from 'react';
import {Button} from 'reactstrap';
import AdminAuthent from '../../src/Components/AdminAuthent/AdminAuthent'
import EventForm from '../Components/EventForm/EventForm';
import EventsRawTable from '../Components/EventsRawTable/EventsRawTable';
import {cGUID, globals} from '../../src/Components/Globals/Globals'

class DiaryView extends Component { 
  constructor(props){
    super(props);
  }

  render = () =>{
    return (
      <div className='view-container'>
        <div>
            <div className='media-container'>
                <h4>Agenda</h4>
                {/** EventsRawTable */}
                <EventsRawTable
                />
            </div>
        </div>
      </div>
    );
  }
}

export default DiaryView;
