import React, { Component } from 'react'
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import axios from 'axios'
import EventCard  from '../EventCard/EventCard'
import {globals} from '../Globals/Globals'

class EventsGrid extends Component{
    constructor(props){
        super(props)
        this.state={events_list:null};
    }

    componentDidMount = async (props) => {
        //Let us fetch events from remote database
        this.fetchEvents(this.props.get_next);
    }

    componentDidUpdate = async (prevProps) => {
        // Typical usage (don't forget to compare props):
        if (this.props.get_next !== prevProps.get_next) {
            this.fetchEvents(this.props.get_next);
        }
    }

    fetchEvents = async (p_bool_get_next) =>{
        //Call micro-service
        //Let us fetch events from remote database
        console.log("p_bool_get_next ->", p_bool_get_next)
        
        let events = await axios.get(`${globals.services.event_filter}${p_bool_get_next?"1":"0"}`);       
        this.setState({events_list : events.data.items}, ()=>{console.log("events_list=>", this.state.events_list)});
    }

    

    dynamicRender = () => {
        let events_card_grid = null;

        console.log("this.state.events_list =>", this.state.events_list)
        if (!((this.state.events_list != null) && (this.state.events_list.length > 0))){
            if (this.props.emptyresult)
            return(this.props.emptyresult)
        }
        else{
                events_card_grid = this.state.events_list.map(
                    (eventItem,i)=>{
                    return  <EventCard 
                                key={i}
                                data={eventItem}
                                onClick={() => {console.log("EventsGrid::OnClick => EventItem", eventItem);if (this.props.onEventClickHandler) this.props.onEventClickHandler(eventItem)}}/>});
            }
        
        return(
            <>
                {this.props.subtitle}
                <div className="partners-columns-container">
                    {events_card_grid}
                </div>
            </>
            );
    }

    render = () => {
        return(
           this.dynamicRender(this.props.ispodcastFilter) 
        )
    }
} 

export default EventsGrid;