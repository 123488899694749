import React, {Component} from "react";
import './Blob.css';

class Blob extends Component{
    constructor(props){
        super(props);
        this.state={};
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------
    reduceImage = (e) =>{
        let imageSource = (e.path && e.path[0]) || e.srcElement;//Safari compliancy
        //console.log("imageSource = ",imageSource);
        var canvas = document.createElement('canvas'),

        context,
        maxWidth = 800,
        maxHeight = 800,
        width = imageSource.width,
        height = imageSource.height;

        if (width > height) {
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }
        }
        canvas.width = width;
        canvas.height = height;
        context = canvas.getContext('2d');
        context.drawImage(imageSource, 0, 0, width, height);

        let canvasData = canvas.toDataURL('image/jpg', 0.6);//Jpeg quality reduced from 0.8 down to 0.6
        if (this.props.removebase64){
            //remove base64 prefix if property is set
            this.props.uploadPicture({data:canvasData?canvasData.substring("data:image/png;base64".length):"", item_id:this.props.item_id});
        }
        else{
            //Keep base 64 prefix as it is
            this.props.uploadPicture({data:canvasData?canvasData:"", item_id:this.props.item_id});
        }        
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------
    storeImageToImg = (e) =>{
        var image = document.createElement('img');
        image.onload = this.reduceImage;

        image.src = e.currentTarget.result;
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------
    readImage = async (aPictureFile) => {
        let _reader = new FileReader();

        _reader.onload = this.storeImageToImg;
        _reader.readAsDataURL(aPictureFile);
    }
    
    //-----------------------------------------------------------------------------------------------------------------------------------------
    resetUpload = () => {
        this.props.uploadPicture({data:"",item_id:this.props.item_id});
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------
    onInputChange = (e) => {
        const errs = [] 
        const files = Array.from(e.target.files)
        const types = ['image/png', 'image/jpeg']
        const _1Mo = 1024 * 1024
        const SizeLimit = 9

        console.clear()
        //Reset input cache value -  to assure trigger if user select the same file again
        e.target.value = null
        console.log("onInputChange")
        if (files.length > 1) {
          const msg = 'Pas plus d\'une image à la fois'
          alert(msg);
          return;
        }
    
        files.forEach((file, i) => {
            let errCount = errs.length
          if (types.every(type => file.type !== type)) {
            errs.push(`'${file.type}' : format non supporté`)
          }

          if (file.size > (SizeLimit * _1Mo)) {
            errs.push(`'${file.name}' image trop volumineuse (max:${SizeLimit}Méga-octets)`)
          }

          
          console.log(`errCount = ${errCount} vs errs.length = ${errs.length}`)
          if (errCount == errs.length){ //None new error occurs
            console.log("readImage:", file.name);
            this.readImage(file);
          }
        })
    
        if (errs.length) {
          return errs.forEach(err => alert(err))
        }
    }

    render(){
        return(
            <div>
                {(!this.props.data) &&
                <label 
                    id="upload-picture-label" 
                    className={this.props.buttonStyle}>{this.props.Caption}
                    <input id="nested-input" type="file" accept="image/*" onChange={this.onInputChange} multiple/>
                </label>}

                {(this.props.data && this.props.data.length > 0) && 
                    <div class="show-image">
                        <img src={(this.props.removebase64)?"data:image/png;base64":"" + this.props.data} className={this.props.pictureStyle}/>
                        {/*<input class="update" type="button" value="Update" />
                        <input class="delete" type="button" value="Effacer" />*/}
                        {!this.props.readOnly &&
                        <input className="btn btn-primary delete" type="button" value="Effacer" onClick={this.resetUpload}/>}
                    </div>
                }
            </div>);
    }
}

export default Blob;