import {globals, cTimeStamp, cGUID} from "../Globals/Globals"
import axios from "axios";


/*  Prototype : _syncDelete
    * Parameter :	JSON object JSON object {"data_guid":"Unique data global id"}
    * Return	:	{remote_data_block:"array of data records"}
    * Purpose 	:   delete data_blob
   *  History   :   Created on 2023-07-27
   */ 
const _syncDelete = (p_s_data_guid, p_onDeleteResult) => {


  console.log("syncDelete::p_s_data_guid->", p_s_data_guid)
  if (!p_s_data_guid){
    console.error("syncDelete -> data_guid not defined")
    return false
  }
  if (!p_onDeleteResult){
    console.error("syncDelete -> p_onDeleteResult not defined")
    return false
  }

  fetch(`${globals.datasync.syncDeleteUrl}
         &data_guid=${p_s_data_guid}`.replace(/[\n\r]/g, '').replace(/\s{1,}/g,'')).then(
    results => {return results.text();}
  ).then(
    server_answer_as_text =>{
      let server_answer_as_object = JSON.parse(server_answer_as_text);
      console.log("_syncDelete:", server_answer_as_object);
      p_onDeleteResult(p_s_data_guid,(server_answer_as_object.deleted_data_count) 
      && (server_answer_as_object.deleted_data_count > 0),null)
    }).catch(
        error => {console.error("Actions: syncDelete failed with error:", error); p_onDeleteResult(p_s_data_guid,false,error.message)}
    )
}

/*  Prototype : _syncStop
    * Parameter :	JSON object {guid:"Unique synchronisation session id",block_size:"number of data returned in each request/ default return all"
    * Return		:	{remote_data_block:"array of data records"}
    * Purpose 	: freed data from session
   *  History   : Created on 2022-06-22
   */ 
const _syncStop = () => {
    console.log("syncStop")

  let l_o_datasync = {session_guid: this.props.current_session};
  let l_s_sync_object_stringified = JSON.stringify(l_o_datasync);

  fetch(`${globals.datasync.syncStopUrl}
         &data_sync=${l_s_sync_object_stringified}`.replace(/[\n\r]/g, '').replace(/\s{1,}/g,'')).then(
    results => {return results.text();}
  ).then(
    server_answer_as_text =>{
      let server_answer_as_object = JSON.parse(server_answer_as_text);
      console.log("_syncStop:"+ server_answer_as_object.last_date_synchro);
    }).catch(
        error => {console.error("Actions: syncStopUrl failed with error:", error)}
    )
}

    /*  Prototype : _syncPullSerialized
    * Parameter :	JSON object {guid:"Unique synchronisation session id",block_size:"number of data returned in each request/ default return all"
    * Return		:	{remote_data_block:"array of data records"}
    * Purpose 	: Returns prepared required data since given guid session
    *  History   : Created on 2022-10-24
    */ 
  const _syncPullSerialized = p_o_syncPullSerializedParams => {
    if (globals.parameters.debugging)
        console.log(`25:------_syncPullSerialized call - ${p_o_syncPullSerializedParams}`)
    return new Promise((resolve, reject) => {
      let l_o_datasync = {session_guid: p_o_syncPullSerializedParams.session_guid, block_size: p_o_syncPullSerializedParams.block_size};
      let l_s_sync_object_stringified = JSON.stringify(l_o_datasync);

      fetch(`${globals.datasync.syncPullSerializedUrl}
             &data_sync=${l_s_sync_object_stringified}`.replace(/[\n\r]/g, '').replace(/\s{1,}/g,'')).then(
        results => {return results.text();}
      ).then(
        server_answer_as_text =>{
          let server_answer_as_object = JSON.parse(server_answer_as_text);
          if ((server_answer_as_object.remote_data_block) && (server_answer_as_object.remote_data_block.length > 0)){
            if (globals.parameters.debugging){
                console.log("25:_syncPullserilized::availables data count:"+ server_answer_as_object.remote_data_block.length);
                console.log("25:_syncPullserilized::availables data flow:"+ JSON.stringify(server_answer_as_object.remote_data_block));
            }
            
            resolve(server_answer_as_object.remote_data_block);
          }
          else{
            console.log("25:_syncPullserilized::failed");
            resolve({remote_data_block:[]});//Return empty array
          }
        }
      )
    })
  }

/**
 * Prototype: getPassThruResult
 * Purpose: Fetch PassThru data asynchronously
 * History: Created on 2023-05-30
 * Parameters sync_seek_request_object = {}
 */

const getPassThruResult = (
                            p_pass_thru_request_object,
                            on_pass_thru_count_callback,
                            on_pass_thru_ready_callback,
                            on_pass_thru_failed_callback) => {

        //Prepare datasync syncPassThru POST request
        const POSTform = new FormData();

        POSTform.append("action", "syncPassThru"); //
        POSTform.append("select_query", p_pass_thru_request_object.select_query);

        //syncPassThru Data
        fetch(`${p_pass_thru_request_object.syncPassThruUrl}`,{method: "POST", body: POSTform})
        .then(results => {return results.text();})
        .then(
            server_answer_as_text => {
                let server_answer_as_object = JSON.parse(server_answer_as_text).remote_data_block;

                if (p_pass_thru_request_object.debugging)
                    console.log("23: getPassThruResult::server_answer_as_object -> ", server_answer_as_object)
                
                if (
                    (server_answer_as_object) 
                    && (server_answer_as_object.length > 0)){
                
                        //Send result back
                        if (on_pass_thru_ready_callback)
                            on_pass_thru_ready_callback(server_answer_as_object)
                    
                }
                else{
                    console.log("23: getPassThruResult: Pas de creneaux disponibles");
                    if (p_pass_thru_request_object.debugging){
                        alert("No availables getPassThruResult data !")
                    }

                    //Trigger onFetchHandler
                    if (on_pass_thru_count_callback) on_pass_thru_count_callback(0)
                }
            }).catch(
                    error => {
                            console.error("Actions: syncSeek failed with error:", error);
                            if (on_pass_thru_failed_callback) on_pass_thru_failed_callback(error)
                        }
                    )
    }


    /**
     * Prototype: getDatasyncMediaOneBlock
     * Purpose: Fetch datablob synchronously in one block based on SynPull rather than syncPullSerialized
     * History: copied from getDatasync on 2023-07-15
     * Parameters sync_seek_request_object = {}
     */

    const getDatasyncMediaOneBlock = (p_sync_seek_request_object) => {

            let l_o_datasyncSeek = {
                session_guid:p_sync_seek_request_object.session_guid, 
                date_synchro: p_sync_seek_request_object.date_synchro};

            let l_s_sync_object_stringified = JSON.stringify(l_o_datasyncSeek);
            let syncSeekUrl = `${p_sync_seek_request_object.syncSeekUrl}
                                &data_sync=${l_s_sync_object_stringified}
                                &company_guid=${p_sync_seek_request_object.company_guid}
                                &seek_filter_clause=${p_sync_seek_request_object.seek_filter_clause}
                                &table_guid=${p_sync_seek_request_object.table_guid}`.replace(/[\n\r]/g, '').replace(/\s{1,}/g,'')

            axios.post(syncSeekUrl)
                    .then(
                        server_answer_as_text => {
                            let server_answer_as_object = server_answer_as_text.data;
                            if (
                                (server_answer_as_object.available_data_count) 
                                && (server_answer_as_object.available_data_count > 0)){
            
                                //Pull data from DataSync layer
                                if (p_sync_seek_request_object.debugging)
                                    console.log("25:Available data ===> "+ server_answer_as_object.available_data_count);

                                //Trigger on_media_count_callback
                                if (p_sync_seek_request_object.on_media_count_callback) p_sync_seek_request_object.on_media_count_callback(server_answer_as_object.available_data_count)

                                //Create an array of promises according to available media items
                                let l_array_PullPromises = [];

                                if (!p_sync_seek_request_object.one_block_pull){
                                    for (let ii=0; ii < server_answer_as_object.available_data_count; ii++)
                                        l_array_PullPromises.push(_syncPullSerialized(l_o_datasyncSeek));//Fetch media data object individually
                                }
                                else{
                                    l_array_PullPromises.push(_syncPullSerialized({
                                        session_guid : p_sync_seek_request_object.session_guid, 
                                        block_size: server_answer_as_object.available_data_count}));//Fetch Media data object individually
                                }

                                //Wait for all promises resolution using Promise.all
                                //Promise.allSettled(l_array_PullPromises).then((media_objects_from_syncPull_promises) =>{
                                Promise.all(l_array_PullPromises).then((media_objects_from_syncPull_promises) =>{
                                    
                                    let l_array_media_cache = []; 

                                    if (p_sync_seek_request_object.debugging){
                                        console.log("27:--------------------------------")
                                        console.log(`27:JSON.stringify(media_objects_from_syncPull_promises) from DataSync => ${JSON.stringify(media_objects_from_syncPull_promises)}`)
                                        console.log("27:--------------------------------")
                                    }

                                    /** WHY CONVERTING an array into another one ??? 
                                     * ==> Because data_blob is not yet parsed as object
                                    */

                                    if (!p_sync_seek_request_object.one_block_pull){
                                        if (media_objects_from_syncPull_promises)//Conditionned on 27-07-2023
                                            media_objects_from_syncPull_promises.forEach((media_data) =>{
                                                let l_o_data_block = media_data[0]; 
                                                
                                                if (p_sync_seek_request_object.debugging)
                                                    console.log("25:ONEBLOCK:l_o_data_block:",l_o_data_block)
                                                
                                                //process synchronously each asynchonous fetched record
                                                if (l_o_data_block
                                                    && l_o_data_block.data_blob) 
                                                l_array_media_cache.push(l_o_data_block);
                                            })

                                        //Send result back
                                        if (p_sync_seek_request_object.debugging) console.log("25:l_array_media_cache",l_array_media_cache)


                                        if (p_sync_seek_request_object.on_media_ready_callback)
                                            p_sync_seek_request_object.on_media_ready_callback(l_array_media_cache)
                                    }
                                    else{
                                        //Batch  hotels debugging with Oliver
                                        if (p_sync_seek_request_object.debugging){
                                            console.log("27:--------------------------------")
                                            console.log(`27:ONEBLOCK:JSON.stringify(media_objects_from_syncPull_promises) from DataSync => ${JSON.stringify(media_objects_from_syncPull_promises)}`)
                                            console.log("27:--------------------------------")
                                        }

                                        if (media_objects_from_syncPull_promises)//Conditionned on 27-07-2023
                                            media_objects_from_syncPull_promises[0].forEach((media_data) =>{
                                                let l_o_data_block = media_data; 
                                                
                                                if (p_sync_seek_request_object.debugging)
                                                    console.log("25:ONEBLOCK:l_o_data_block:",l_o_data_block)
                                                
                                                //process synchronously each asynchonous fetched record
                                                try{
                                                    //if (parseInt(l_o_data_block.data_blob.form.version) === parseInt(p_sync_seek_request_object.form_version)) 
                                                    l_array_media_cache.push(l_o_data_block);
                                                }
                                                catch(e){
                                                    console.error("27:ONEBLOCK:Invalid l_o_data_block.data_blob ",e.message)
                                                    return false;
                                                }
                                            })

                                        //Send result back
                                        if (p_sync_seek_request_object.on_media_ready_callback)
                                            p_sync_seek_request_object.on_media_ready_callback(l_array_media_cache)
                                    }
                                })
                            }
                            else{
                                console.log("Actions: Pas de données disponibles");
                                if (p_sync_seek_request_object.debugging){
                                    alert("Pas de données disponibles !")
                                }

                                //Trigger onFetchHandler
                                if (p_sync_seek_request_object.on_media_count_callback) p_sync_seek_request_object.on_media_count_callback(0)
                            }
                        }).catch(
                                error => {
                                        console.error("Actions: syncSeek failed with error:", error);
                                        if (p_sync_seek_request_object.on_media_failed_callback) 
                                            p_sync_seek_request_object.on_media_failed_callback(error)
                                    }
                                )
        }
        

/**
 * Query a field value from data blob using tag name
 */

 const getTagValue = (data_blob_form, requested_field) => {
    let data_blob_form_stringyfied = JSON.stringify(data_blob_form)
    const pattern = new RegExp(`"tag":"${requested_field}(.*?)"`, 'gm');

    let search_result = pattern.exec(data_blob_form_stringyfied)
    if (true){
        console.log("getTagValue::regex->",search_result);
        console.log("getTagValue::get_form_value->",data_blob_form_stringyfied)
    }

    return search_result?search_result[1]:null
}

/**
 * Purpose : Query a selected field value from data blob
 * History : Created on 2023-04-20
 */

 const getFormSelectedValues = (data_blob_form, requested_field) => {
    let data_blob_form_stringyfied = JSON.stringify(data_blob_form)
    const pattern = new RegExp(`{"name":"${requested_field}",.*?,"selected_values":"(.*?)"`, 'gm');
    /**
     *  Sample from SearchPromises#86
     * l_array_ExecResult = reg_searchTerm.exec(l_sBookTextDataWithoutHtmlTags);
            i = (l_array_ExecResult) ? l_array_ExecResult['index'] : -1;
     */
    let search_result = pattern.exec(data_blob_form_stringyfied)
    if (false){
        console.log("regex->",search_result);
        console.log("get_form_value->",data_blob_form_stringyfied)
    }

    return search_result?search_result[1]:"<empty>"
}
/**
 * Write form to data blob
 */
const saveFormToDatasync = async (
        p_data_guid, 
        p_o_form,
        p_s_company_guid,
        p_s_table_guid,
        p_dt_createstamp,
        p_dt_updatestamp,
        p_dt_deletestamp,
        on_save_handler,
        on_update_handler,
        on_failed_handler,
        p_extra_data
        ) => {

let p_sync_seek_request_object = {debugging:false}

if (p_sync_seek_request_object.debugging)
    console.log(`this.form:${JSON.stringify(p_o_form)}`)

//Post object using DataSyncLayer
//Copy datasync compliant object data from state structure
let formBlobObject = {
    data_guid: p_data_guid?p_data_guid:cGUID(), 

    data_blob:{
        form: p_o_form
    }}

if (p_dt_createstamp){
    formBlobObject.createstamp = p_dt_createstamp
}

if (p_dt_updatestamp){
    formBlobObject.updatestamp = p_dt_updatestamp
}

if (p_dt_deletestamp){
    formBlobObject.deletestamp = p_dt_deletestamp
}

let l_o_datasync = Object.assign({}, formBlobObject); 

if (p_sync_seek_request_object.debugging){
    console.log("l_o_datasync to be stored => ", l_o_datasync)
}

//Set datasync container sytem properties values
//Set user custom data_blob properties values
l_o_datasync.data_blob.modified = formBlobObject?1:0; //Reset modified flag
//and so on...

//Stringify whole datasync object one more time
let l_s_sync_object_stringified
try{
    l_s_sync_object_stringified = JSON.stringify(l_o_datasync).replace(/'/g, "'").replace(/\\n/g, '\\\\n');
    if (p_sync_seek_request_object.debugging)
        console.log(`l_s_sync_object_stringified succedeed :${l_s_sync_object_stringified}`)
}
catch(err){
    console.error(`l_s_sync_object_stringified:${l_s_sync_object_stringified}`)
    alert(`l_s_sync_object_stringified StringiFy failed with error:${err}`)
}

//Prepare datasync syncPushSerialized POST request
const POSTform = new FormData();

POSTform.append("action", "syncPushSerialized"); //
POSTform.append("company_guid", p_s_company_guid);
POSTform.append("table_guid", p_s_table_guid);

POSTform.append("data_sync", l_s_sync_object_stringified);

//SyncPush Data
fetch(`${globals.datasync.syncPushSerializedUrl}`,{method: "POST", body: POSTform})
.then(results => {return results.text();})
.then(
    sync_server_response => {
        let json_response = JSON.parse(sync_server_response);
        if (p_sync_seek_request_object.debugging)
            console.log("json_response ->", json_response)
        if (json_response.data_blob){
            //Save succedeed
            if ((p_data_guid == null) && on_save_handler) on_save_handler(json_response.data_blob, p_extra_data);
            //Update succedeed
            if ((p_data_guid != null) && on_update_handler) on_update_handler(json_response.data_blob, p_extra_data);
            
            //debugging
            if (p_sync_seek_request_object.debugging){
            let data_blob = json_response.data_blob
            console.log("data_blob",data_blob)
            }                
        }
        else{
            //Save or update failed
            console.error("DataSync::saveFormToDatasync => Save or update failed")
            if (on_failed_handler) on_failed_handler("Internal DataSync save error", p_extra_data);
        }
    }).catch(err => {alert(`Erreur de sauvegarde ${err}`)})
}

/**
 * syncSeek
 * Purpose : Check for a DataTable availability from Datasync givent  a specifit Query clause filter
 * p_oSyncSeekParam:{syncSeekUrl, seek_filter_clause : SQL seek filter, table_guid, company_guid}
 */
const syncSeek = async (p_oSyncSeekParam, callBack) => {
    
    let l_o_datasyncSeek = {
        session_guid:cGUID(), 
        date_synchro: '1970-07-10 063050'};
    let l_s_sync_object_stringified = JSON.stringify(l_o_datasyncSeek);
    let syncSeekUrl = `${globals.datasync.syncSeekUrl}
                        &data_sync=${l_s_sync_object_stringified}
                        &company_guid=${p_oSyncSeekParam.company_guid}
                        &seek_filter_clause=${p_oSyncSeekParam.seek_filter_clause}
                        &table_guid=${p_oSyncSeekParam.table_guid}`.replace(/[\n\r]/g, '').replace(/\s{1,}/g,'')

    if (globals.parameters.debugging){
        console.log(`syncSeektUrl => ${syncSeekUrl}`)
    }

    await axios.post(syncSeekUrl)
            .then(
                server_answer_as_text => {
                    let server_answer_as_object = server_answer_as_text.data;
                    if (
                        (server_answer_as_object.available_data_count) 
                        && (server_answer_as_object.available_data_count > 0)){
     
                        //Pull data from DataSync layer
                        if (globals.parameters.debugging)
                            console.log("AsyncDebug::Available data ===> "+ server_answer_as_object.available_data_count);

                        //return available_data_count value
                        callBack(l_o_datasyncSeek, server_answer_as_object.available_data_count)

                    }
                    else{
                        console.log("AsyncDebug::Actions: Pas de correspondance");
                        if (globals.parameters.debugging){
                            console.log("AsyncDebug::No availables users.")
                        }

                        //Trigger onFetchHandler
                        callBack(null,0)
                    }
                }).catch(
                        error => {console.error("Actions: syncSeekUrl failed with error:", error);callBack(null,-1)}
                        )
}

/**
 * Prototype : getFormValue3Tiers
 * Purpose : Query a field value from new data blob, data_tier property
 */

const getFormValue3Tiers = (p_data_blob, p_requested_field) => {
    let nextFieldData = ""
    try{
        /** Return data value form field object */
        nextFieldData = p_data_blob.data_tier[p_requested_field]?p_data_blob.data_tier[p_requested_field]:"";
    }
    catch(e){
        if (globals.parameters.debugging)
        console.log("d3t:getFormValue3Tiers[", p_requested_field ,"] nextFieldData->", nextFieldData, " error caught ->",e)
    }
    
    return nextFieldData
}

/**
 * Write data_tier form to data blob
 */
const saveDataTierToDatasync = async (
    p_data_guid, 
    p_o_data_blob,
    p_s_company_guid,
    p_s_table_guid,
    p_dt_createstamp,
    p_dt_updatestamp,
    p_dt_deletestamp,
    on_save_handler,
    on_update_handler,
    on_failed_handler,
    p_extra_data
    ) => {

if (globals.parameters.debugging_data_tier)
    console.log(`10/07:this.data_tier:${JSON.stringify(p_o_data_blob)}`)

//Post object using DataSyncLayer
//Copy datasync compliant object data from state structure
let formBlobObject = {
data_guid: p_data_guid?p_data_guid:cGUID(), 

data_blob:{
    data_tier: p_o_data_blob.data_tier
}}

if (p_dt_createstamp){
formBlobObject.createstamp = p_dt_createstamp
}

if (p_dt_updatestamp){
formBlobObject.updatestamp = p_dt_updatestamp
}

if (p_dt_deletestamp){
formBlobObject.deletestamp = p_dt_deletestamp
}

let l_o_datasync = Object.assign({}, formBlobObject); 

if (globals.parameters.debugging){
console.log("l_o_datasync to be stored => ", l_o_datasync)
}

//Set datasync container sytem properties values
//Set user custom data_blob properties values
l_o_datasync.data_blob.modified = globals.parameters.form_modified_version; //ReUse moidified flag as modified version
//and so on...

//Stringify whole datasync object one more time
let l_s_sync_object_stringified
try{
l_s_sync_object_stringified = JSON.stringify(l_o_datasync).replace(/'/g, "'").replace(/\\n/g, '\\\\n');
if (globals.parameters.debugging)
    console.log(`l_s_sync_object_stringified succedeed :${l_s_sync_object_stringified}`)
}
catch(err){
console.error(`l_s_sync_object_stringified:${l_s_sync_object_stringified}`)
alert(`l_s_sync_object_stringified StringiFy failed with error:${err}`)
}

//Prepare datasync syncPush POST request
const POSTform = new FormData();

POSTform.append("action", "syncPushSerialized"); //
POSTform.append("company_guid", p_s_company_guid);
POSTform.append("table_guid", p_s_table_guid);

POSTform.append("data_sync", l_s_sync_object_stringified);

//SyncPush Data
fetch(`${globals.datasync.syncPushSerializedUrl}`,{method: "POST", body: POSTform})
.then(results => {return results.text();})
.then(
sync_server_response => {
    let json_response = JSON.parse(sync_server_response);
    if (globals.parameters.debugging)
        console.log("json_response ->", json_response)
    if (json_response.data_blob){
        //Save succedeed
        if ((p_data_guid == null) && on_save_handler) on_save_handler(json_response, p_extra_data);
        //Update succedeed
        if ((p_data_guid != null) && on_update_handler) on_update_handler(json_response, p_extra_data);
        
        //debugging
        if (globals.parameters.debugging){
        let data_blob = json_response.data_blob
        console.log("data_blob",data_blob)
        }                
    }
    else{
        //Save or update failed
        console.error("DataSync::saveFormToDatasync => Save or update failed")
        if (on_failed_handler) on_failed_handler("Internal DataSync save error", p_extra_data);
    }
}).catch(err => {alert(`Erreur de sauvegarde ${err}`)})
}


export {saveFormToDatasync, getFormSelectedValues, getPassThruResult, _syncStop, _syncPullSerialized, _syncDelete, getDatasyncMediaOneBlock,
    syncSeek, getFormValue3Tiers, saveDataTierToDatasync};