import { Component  } from "react";
import NestedTitlePicture from '../Components/NestedTitlePicture/NestedTitlePicture'
import RestranscripteursPicture from '../assets/home/thonon_home_picture.jpeg'
import {cGUID} from '../Components/Globals/Globals'
import EventsGrid from '../Components/EventsGrid/EventsGrid'
import VisitUs from '../Components/VisitUs/VisitUs'
import { FaEnvelope, FaClock, FaMobile, FaMapMarkerAlt } from 'react-icons/fa'
import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {globals,setCookies, removeCookies} from '../Components/Globals/Globals';
import BibleForm from "../Components/BibleForm/BibleForm";
import ContactForm from "../Components/ContactForm/ContactForm";
import DailyWorshipText from "../Components/DailyWorshipText/DailyWorshipText";

class HomeView extends Component{
    constructor(props){
        super(props)
        this.state={currentSession:cGUID()};
    }

    render(){
        let title_color = "white"
        let title_font_size = "3em"
        let container_width = "100%"
        let container_height = "350px"
        let carousel_interval = ""

        return(
            <div className="view-container intro-view">
                {/*** Picture banner */}
                <div>
                    <NestedTitlePicture 
                            width={container_width}
                            height={container_height}
                            pictureTitle = {"Eglise Adventiste du 7ème jour de Thonon"}
                            pictureURL={RestranscripteursPicture}
                            color={title_color}
                            fontSize={title_font_size}
                            />    
                </div>
                {/** Global Cookies debugging */}
                {globals.parameters.cookie_debugging &&
                <div>
                    <br/>
                    <Button onClick={()=>{setCookies({name:"Mab", first_name:"Pat", admin:1, enabled:1})}}>setCookie debugging</Button>
                    <br/>
                    <Button onClick={()=>{removeCookies()}}>removeCookies debugging</Button>
                </div>}
                
                {/** Daily Worship */}
                <div>
                    <div className="subtitle">Méditation du jour</div>
                    <p>Avec Dieu chaque jour Méditez chaque jour un passage biblique tiré des écrits de <i>Ellen Gould White</i>. Quel que soit votre questionnement, 
                        vos doutes, vos épreuves; Nous avons la conviction que <strong>Jésus</strong> est la réponse !</p>
                    <DailyWorshipText shorten={true}/>

                    <a className="dropdown-item rounded-button" href="/dailyworship">Lire la méditation...</a>
                </div>

                {/** Diary */}
                
                {true && 
                <div>
                    <EventsGrid
                        subtitle= {<div className="subtitle">Prochains évènements</div>}
                        emptyresult = {<></>}
                        get_next = {true} 
                        limit = {3}
                        />
                </div>}

                {/** Audio records / PodCasts */}
                {/**
                <div>
                    <EventsGrid
                        subtitle= {<div className="subtitle">Dernières prédications</div>}
                        emptyresult = {<></>}
                        get_next = {false} 
                        limit = {3}
                        />
                </div>
                 */}

                {/** Recevoir  une  Bible  gratuite */}
                <div className="subtitle">Recevoir une Bible gratuitement</div>
                <h3>Formulaire de demande de Bibles</h3>
                <BibleForm/>
                
                
                
                {/*** CONTACT US */}
                <div className="subtitle">Nous rejoindre</div>
                <div className="contact-container">
                    {/** Map + Contact */} 
                    <div className="contact-map">
                        <VisitUs/>
                    </div>

                    {/** Contact */}
                    <div className="contact-form">
                            <h4><FaEnvelope/><span className="etl-icon icon-envelope mbr-iconfont mbr-iconfont-btn"></span>courriel</h4>
                            <ContactForm/>
                    </div>
                </div>

                {/*** Contact pastor */}
                <div className="subtitle">Contacter notre pasteur</div>
                <div className="pasteur-container">
                     {/** Photo pasteur + Identité */}
                    <div className="pasteur-photo">
                        {/** Photo pasteur + message button*/}
                    </div>

                    <div className="pasteur-identity">
                        {/** Notre pasteur */}
                        <h3>Jean-Manuel Séraline</h3>
                        <div>Jean-Manuel.Seraline@adventiste.org</div>
                        <div>+33 6 24 10 36 89</div>   
                    </div>
                </div>

                {/** 
                

                <h4>Facebook</h4>
                <p>
                https://www.facebook.com/ASJThonon/
                </p>

                <h4>Verset du mois</h4>
                <q>Recherchez l'Eternel pendant qu'il se laisse trouver! Faites appel à lui tant qu'il est près! <b>(Esaïe 55.6)</b></q>*/}
            </div>
        )
    }
}

export default HomeView;