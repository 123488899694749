import React, { Component } from 'react';
import {Button} from 'reactstrap';
import {cGUID, globals, media_form} from '../../src/Components/Globals/Globals'
import MediaRawTableDataSync from '../Components/MediaRawTableDataSync/MediaRawTableDataSync';
import DynamicForm from '../../src/Components/DynamicForm/DynamicForm'

class AdminView extends Component { 
  constructor(props){
    super(props);
    this.state={current_media:null, open_media_form:false}
  }


  render = () =>{
    return (
      <div className='view-container'>
        <div>
            <div className='media-container'>
              {this.state.open_media_form && <>
                <h4>Ajout / Modification d'un média</h4>
                
                {/** MediaForm */}
                <DynamicForm
                  form = {media_form}
                  company_guid = {globals.datasync.thononadvent_company_guid}
                  table_guid= {globals.datasync.media_table_guid}
                  onFormSaved = {(saved_data) => {alert("Formulaire média enregistré :-)")}}
                  onFormUpdated = {(updated_data) => {alert("Formulaire média modifié :-)")}}
                  onFormFailed = {() => {if (globals.parameters.save_failed_alert) alert("Erreur de sauvegarde du Média :-(")}}
                  clearOnSave = {true}
                  clearOnUpdate = {true}
                  sticky_button = {true}
                  custom_button_caption = "Fermer"
                  custom_button_handler = {()=>{this.setState({current_media:null, open_media_form:false})}}//Return to table mode
                />
              </>}
              
              {!this.state.open_media_form && <>
                <h4>{globals.menu.terminaladmin}</h4>
                <div className='top-media-toolbar'>
                  <Button color="primary" onClick={()=>{this.setState({open_media_form:true})}}>Nouveau média</Button>
                </div>
                <MediaRawTableDataSync
                  table={true}
                  onEditMedia={this.onMediaEditFromRawTableDataSync}
                  current_session={this.props.current_session}
                  />
              </>}
            </div>
        </div>
      </div>
    );
  }
}

export default AdminView;
