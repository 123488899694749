/**
 * ThononAdvent Website
 */

import React, { Component } from 'react';
import {Route, Routes, BrowserRouter} from "react-router-dom"
import StickyMenu3 from './Components/StickyMenu3/StickyMenu3';
import HomeView from '../src/Views/HomeView';
import AdminView from '../src/Views/AdminView';
import EventsAdminView from './Views/EventsAdminView';
import TerminalView from '../src/Views/TerminalView';
import DiaryView from './Views/DiaryView';
import FooterPage from './Components/FooterPage/FooterPage';
import AuthentView from './Views/AuthentView';
import DailyWorshipView from './Views/DailyWorshipView';

import './App.css';
import './css/ThononAdventTemplate.css'
import { globals, cGUID} from './Components/Globals/Globals';

const App = () => {

  const session1 = cGUID()
  const session2 = cGUID()

  //Rendering
  return (
      <BrowserRouter>
        <div>          
        {window.location.pathname != "/terminal" &&
          <div id="menu">
            <StickyMenu3/>
          </div>}
         
          <Routes>
            <Route exact path = "/" element={<HomeView/>} />
            <Route  path = "/agenda" element={<DiaryView/>} />
            <Route  path = "/eventsadmin" element={<EventsAdminView/>} />
            {session1 &&       
            <Route  path = "/playlist" element={<AdminView current_session={session1}/>} />}

            <Route  path = "/preview" element={<TerminalView interval={globals.parameters.interval} preview={true} current_session={session2}/>} />
            <Route  path = "/terminal" element={<TerminalView interval={globals.parameters.interval} preview={false} current_session={session2}/>} />
            <Route  path = "/authent" element={<AuthentView/>} />
            <Route path = "/dailyworship" element={<DailyWorshipView/>} />
          </Routes>
        </div>
        
        {/**
         * <p>session1: {session1}</p>
         * <p>session2: {session2}</p> 
         */}
        <FooterPage/>
      </BrowserRouter>        
  );
  
}

export default App;
