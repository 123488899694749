import {globals, cTimeStamp, cGUID} from "../Globals/Globals"
import axios from "axios";

/**
   * Prototype : send_custom_email
   * Purpose : Deposit acknowledge email 
   */
 const send_custom_email = async (mailsync_object_parameters) => {
    try{
          //Post confirmation e-mail back to user
          let mailResult = await axios.post(`${globals.mailsync.url}/send-custom-email`,
            mailsync_object_parameters
          );

          console.log("mailResult =>", mailResult);
          return true;

    } catch(err){
        alert(`Une erreur est survenue :${err}`);
        return false;
    }
  }


export {send_custom_email};