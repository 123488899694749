import React from 'react'
import { globals } from '../Globals/Globals'
import { render } from 'react-dom'
import '../../css/ThononAdventTemplate.css'
import { Button} from 'reactstrap';

const EventCard = (props) => {

    const media_button = (media_name) => {
            const isPicture = ["jpeg","jpg","png","pdf"];
            const isVideo = ["mp4","mov","avi"];
            const isAudio = ["mp3","wav","raw"];

            let split_media_name = media_name.split(".");
            let extension =  split_media_name[split_media_name.length-1];
            let media_button_class_name = ""

            //alert(extension)

            if (isPicture.includes(extension)){
                media_button_class_name = "media_button picture"
            } 

            if (isVideo.includes(extension)){
                media_button_class_name = "media_button video"
            } 
    
            if (isAudio.includes(extension)){
                media_button_class_name = "media_button audio"
            } 
    {/** 
            return(
                <a href= {`${globals.services.event_media}/${props.data.media_filename}`}
                    className={media_button_class_name}></a>
            )
*/}

            if (media_button_class_name.length == 0)
                return(<></>)
                
            return(
                <Button 
                    className={media_button_class_name}
                    onClick={()=>{
                        window.open(`${globals.services.event_media}/${props.data.media_filename}`, '_blank');}}>
                </Button>
                
            )
        }
    
        return(
            <div className="event-container">
                <div className='event-date'>
                    {`${props.data.day}/${props.data.month}/${props.data.year}`}
                </div> 

                <div className='event-title'>
                    {props.data.title}
                </div>

                <div className='event-description'>
                    {props.data.text}
                </div>

                <div className='media-button-container'>
                    {media_button(props.data.media_filename)}
                </div>
            </div>
        )
    }

export default EventCard;