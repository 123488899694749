import Cookies from 'universal-cookie';

const production = true;
const local = !production;
const debugging = false;
const cookie_debugging = false;
const ASUS = false;
const MAC = !ASUS;

const cookies = new Cookies();
const _seconds = 1;
const _minutes = 60 * _seconds;
const _cookie_name = "thonon_user_was_authentified"

const randomize = (min, max) => {
    return Math.floor(min + Math.random() * (max - min));
}

const wScrollTo = (pStargetAnchorId) => {
    let targetDomAnchor = document.getElementById(pStargetAnchorId)
    if (targetDomAnchor)
        targetDomAnchor.scrollIntoView({
            behavior: "smooth", 
            block: "start",
            inline: "nearest",
        });
    else{
        if (globals.parameters.debugging){
            console.log(`wScrollTo failed => ${pStargetAnchorId} not found in DOM !`)
        }
    }
}


const setCookies = (cookie_value) => {
    cookies.set(_cookie_name, cookie_value, { path: '/', maxAge : 30 * _minutes});
    if (cookie_debugging){
        console.log(`cookie set with value ${JSON.stringify(cookie_value)}`);
    }
} 

const removeCookies = () => {
    cookies.set(_cookie_name, null);
    if (cookie_debugging){
        console.log(`cookie value reset`);
    }
} 

const getCookies = () => {
    let cookie_value = cookies.get(_cookie_name);
    const cookie_as_object = (cookie_value !== 'undefined' && cookie_value != null)?cookie_value:null;

    if (cookie_debugging){
        console.log(`cookie as string ${JSON.stringify(cookie_value)}`);
    }

    return cookie_value;
}

const adminConnected = () => {
    try{
        let l_Ocookie = getCookies()
        console.log("l_Ocookie:", l_Ocookie)
        return (l_Ocookie.admin && l_Ocookie.admin == 1)
    }
    catch(e){
        return false
    }
}

/* Prototype 	: cGUID
* Purpose 		: Generates a Global Unique ID
* Return		  :	Return GUID
* History   	: Created on 2015-02-06
*
**/
const cGUID = () => {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};

  /***
 * Prototype 	: 	cTimeStamp
 * Purpose 		: 	return TimeStamp as "AAAA-MM-JJ HHMMSS" format
 * History		: 	Created on 2014-08-06
 *
 **/
const cTimeStamp = () => {
    let lDate = new Date();
    let l_sTimeStamp = '';

    l_sTimeStamp = 1900 + lDate.getYear();
    l_sTimeStamp += '-';
    l_sTimeStamp += ('0' + (lDate.getMonth() + 1)).slice(-2);
    l_sTimeStamp += '-';
    l_sTimeStamp += ('0' + lDate.getDate()).slice(-2);
    l_sTimeStamp += ' ';

    l_sTimeStamp += ('0' + lDate.getHours()).slice(-2);
    l_sTimeStamp += ('0' + lDate.getMinutes()).slice(-2);
    l_sTimeStamp += ('0' + lDate.getSeconds()).slice(-2);

    return (l_sTimeStamp);
}

const globals = {
    parameters : {
            admin_menu_enabled : true,
            website:"thonon.adventiste.org",
            debugging : debugging, 
            cookie_debugging:cookie_debugging,
            sticky3_debug: false,
            local:local,
            production:!local,
            interval:10000,
            form_version:1
        },
    menu : {
            home : "Accueil",
            eventsadmin : "Editer l'agenda",
            terminaladmin : "Editer la borne",
            /*chainmenu : "Chaine de prières",*/
            terminalpreview : "Aperçu de la borne",
            terminal : "Borne",
            about_us: "A propos de nous...",
            contact: "Nous contacter",
            login:"Connexion...",
            logoff:"Déconnexion...",
            daily_worship : "Méditation du jour",
    },
    datasync :{
        /*DATASYNC*/
        thononadvent_company_guid:local?"3":"4",
        media_table_guid:local?"4":"1",
        chain_table_guid:local?"16":"160",
        bible_request_table_guid:local?"15":"150",
        contact_request_table_guid:local?"AEC36D82-B322-22CF-4E96-D521ABF4E554":"9917FB8C-7E86-9924-E2E9-EADADD7384D9",
        
        /*  MAC local */
        ...(MAC && local && {
        syncTestUrl : "http://localhost:8888/datasync-service/test.php",
        syncPushSerializedUrl : "http://localhost:8888/datasync-service/Sync.php",
        syncStartUrl: "http://localhost:8888/datasync-service/Sync.php?action=syncStart",
        syncDeleteUrl: "http://localhost:8888/datasync-service/Sync.php?action=syncDelete",
        syncSeekUrl: "http://localhost:8888/datasync-service/Sync.php?action=syncSeek",
        syncStopUrl: "http://localhost:8888/datasync-service/Sync.php?action=syncStop",
        syncPullUrl : "http://localhost:8888/datasync-service/Sync.php?action=syncPull",
        syncPullSerializedUrl : "http://localhost:8888/datasync-service/Sync.php?action=syncPullSerialized",
        syncMobilePullUrl : "http://localhost:8888/datasync-service/Sync.php?action=syncMobilePull",
        syncPassThruUrl : "http://localhost:8888/datasync-service/Sync.php?action=syncPassThru"}),
            
        
        /* Neuronal.bible Production Server */
        ...(production && {
        syncTestUrl : "http://www.neuronal.bible/datasync-service2/test.php",
        syncPushSerializedUrl : "https://www.neuronal.bible/datasync-service-thononadvent/Sync.php",
        syncStartUrl: "https://www.neuronal.bible/datasync-service2/Sync.php?action=syncStart",
        syncDeleteUrl: "http://www.neuronal.bible/datasync-service/Sync.php?action=syncDelete",
        syncSeekUrl: "https://www.neuronal.bible/datasync-service2/Sync.php?action=syncSeek",
        syncStopUrl: "https://www.neuronal.bible/datasync-service2/Sync.php?action=syncStop",
        syncPullUrl : "https://www.neuronal.bible/datasync-service2/Sync.php?action=syncPull",
        syncPullSerializedUrl : "https://hotels.immo/datasync-service3/Sync.php?action=syncPullSerialized",
        syncMobilePullUrl : "https://www.neuronal.bible/datasync-service2/Sync.php?action=syncMobilePull",
        syncPassThruUrl : "https://www.neuronal.bible/datasync-service2/Sync.php?action=syncPassThru"})
        /**/
    },
    mailer : {
        thonon_advent_staff_mailing_list:"thononadvent@neuronal.bible",
        ne_pas_repondre_email : "ne_pas_repondre@thononadvent.com",
        signature: "Eglise Adventiste de Thonon les bains",
        bible_request_mail_subject: "Demande de Bible gratuite",
        contact_request_mail_subject: "Demande de contact depuis le site de l'Eglise de Thonon les bains",
        bible_request_mail_message:"Je voudrais recevoir une Bible gratuite",
        bible_request_mail_acknowledge_subject: "Confirmation de demande de Bible gratuite",
        bible_request_mail_acknowledge_message: "Nous avons bien reçu votre demande de Bible gratuite, vous allez la recevoir sous peu.",
        contact_request_mail_acknowledge_message: "Nous avons bien reçu votre demande de contact, nous vous répondrons sous peu."
    }
    ,
    mailsync : {
        ...(local && {url : 'http://localhost:4404'}),
        ...(production && {url : 'https://thcc.mailer.neuronal.bible'})
    }
    ,    
    share:{
        worship:{
            ...(local && {url : 'http://localhost:3000/dailyworship'}),
            ...(production && {url : 'https://thonon.adventiste.org/dailyworship'})
        }
    }

    ,
    weekly : {
        ref:"2 Chroniques 20.22",
        verse:"Au moment où l'on commençait les chants et les louanges, l'Eternel plaça une embuscade contre les Ammonites, les Moabites et les habitants de la région montagneuse de Séir qui étaient venus contre Juda, et ils furent battus."
    }
    ,
    services :{
        /* local */
        ...(local && {
        event_filter : 'http://localhost:4000/event-filter?get_next=',
        event_get : 'http://localhost:4000/event-get',
        event_create : 'http://localhost:4000/event-create',
        event_update : 'http://localhost:4000/event-update',
        event_delete : 'http://localhost:4000/event-delete',
        event_upload : 'http://localhost:4000/upload',
        event_media : 'http://localhost:4000/media',
        event_check_admin : 'http://localhost:4000/event_check_admin',
        mailer_send_authenticate: 'http://localhost:4404/send-authenticate-code',
        mailer_send_message: 'http://localhost:4001/send-mail',
    }),
        
        
        /* Webcloud Server */
        ...(production && {
        event_filter : 'https://adv-events-service.thononadvent.com/event-filter?get_next=',
        event_get : 'https://adv-events-service.thononadvent.com/event-get',
        event_create : 'https://adv-events-service.thononadvent.com/event-create',
        event_update : 'https://adv-events-service.thononadvent.com/event-update',
        event_delete : 'https://adv-events-service.thononadvent.com/event-delete',
        event_upload : 'https://adv-events-service.thononadvent.com/upload',
        event_media : 'https://adv-events-service.thononadvent.com/media',
        event_check_admin : 'https://adv-events-service.thononadvent.com/event_check_admin',
        mailer_send_authenticate: 'https://adv-mailer-service.thononadvent.com/send-authenticate-code',
        mailer_send_message: 'https://adv-mailer-service.thononadvent.com/send-mail',
    }),
    }
}

/** Fields structure declaration */
const media_form = {
    "version" : globals.parameters.form_version,
    "Rows" : [{//Row-1
      "Cols" : [
        {//col-1
        "Fields" : [
          {
            "name" : "file_data",
            "title" : "Média",
            "placeholder" : "Diapositive",
            "input_type" : "blob",
            "min_length" : -1,
            "max_length" : -1,
            "required"  : true
          }],
          },
          {//Col-2
            "Fields":[
            {
            "name" : "Titre",
            "placeholder" : "Nom du Média",
            "input_type" : "text",
            "min_length" : 3,
            "max_length" : 10,
            "required"  : false
            }
            ],
          }],
        },//End of Row-1
  
        {//Row-2
          "Cols":[
            {//Col-1
              "Fields":[
                {
                    "name" : "Active",
                    "title" : "Diapositive active",
                    "placeholder" : "Oui / Non",
                    "combo_list" : ["Oui","Non"], 
                    "input_type" : "radio",
                    "min_length" : -1,
                    "max_length" : -1,
                    "required"  : false
                  }]},
            ]},              
        ]}


export {globals, cGUID, cTimeStamp, getCookies, setCookies, 
    removeCookies, adminConnected, randomize, wScrollTo, media_form}