/*
EventForm Component
*/
import React, {Component} from 'react';
import { Button, ModalBody, ModalFooter} from 'reactstrap';
import Blob3 from '../Blob3/Blob3';
import {globals} from '../Globals/Globals'
import Editor from '../Editor/Editor';
import DatePicker, { registerLocale, setDefaultLocale }  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';
import MediaUpload from '../MediaUpload/MediaUpload';

class EventForm extends Component{
    constructor(props){
        super(props);

        this.clearObject={
            date:"",
            day:0,
            month:0,
            year:0,
            did_not_change : true,
            media_filename:"",
            title:"",
            text:"",
            is_podcast:false,
            formErrors: {
                date:'',
                media_filename:'', title:'', 
                
                text:'', 
                is_podcast:"",
                },
            is_podcasttext:`Il s'agit d'une vidéo`,
            idpartner:-1,
            offers:[],
            gallery:[]
        }

        this.state = this.clearObject;
        this.ispodcastCheckBoxRef = React.createRef();
    }

    clearForm = () => {
        this.clearObject = Object.assign(this.clearObject, this.props.data)
        if (this.props.data){
            let provided_date = `${this.props.data.day}/${this.props.data.month}/${this.props.data.year}`
            if (globals.parameters.debugging){
                console.log("provided_date =>", provided_date)
            }
            this.clearObject.date = this.convertDate(provided_date)
        }
        this.setState(this.clearObject);
    }

    onFormSubmitHandler = async event => {
        event.preventDefault();
        let canSubmit = this.onCheckFormValidationBeforeSubmit();

        if (globals.parameters.debugging){
            console.log("canSubmit:", canSubmit)
        }

        if (!canSubmit) return;//Cancel submit

        //Call micro-service
        if (globals.parameters.debugging){
            console.log(`onFormSubmitHandler::JSON.stringify(this.state) => ${JSON.stringify(this.state)}`)
            console.log("onFormSubmitHandler::micro-service => this.partner_service:", globals.services.event_create,"state size", Math.floor(JSON.stringify(this.state).length),"Mo state =>", this.state);
        }

        var serviceResult = null
        try{
            await fetch(
                    `${globals.services.event_create}`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                                    //'Content-Type': 'application/x-www-form-urlencoded'},
                        method: "POST", 
                        body: JSON.stringify(this.state)}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    if (globals.parameters.debugging)
                        console.log("sever_response =>", server_response)

                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("client_create_event_log => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //
                        alert("Evènement créé !")

                        //Clear form
                        if (!globals.parameters.debugging)
                            this.clearForm();

                        //Trigger on terminate
                        if (this.props.onTerminate){
                            this.props.onTerminate()
                        }                                                    
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }
    }  

    onFormUpdateHandler = async event => {
        event.preventDefault();

        if (!this.onCheckFormValidationBeforeSubmit()) return;//Cancel submitt

        //Call micro-service
        if (globals.parameters.debugging){
            console.log(`onFormUpdateHandler::JSON.stringify(this.state) => ${JSON.stringify(this.state)}`)
            console.log("onFormUpdateHandler::micro-service => this.partner_service:", globals.services.event_update,"state size", Math.floor(JSON.stringify(this.state).length),"Mo state =>", this.state);
        }

        var serviceResult = null
        try{
            await fetch(
                    `${globals.services.event_update}`,{
                        headers: {
                                    'Content-Type': 'application/json'},
                                    //'Content-Type': 'application/x-www-form-urlencoded'},
                        method: "POST", 
                        body: JSON.stringify(this.state)}).then(
                results => {return results.text();}
                ).then(
                  (server_response) =>{
                    if (globals.parameters.debugging)
                        console.log("sever_response =>", server_response)

                    serviceResult = JSON.parse(server_response);
                    //------------------------------------------ Asynchronous promise result handle
                    //Get service aknowledge before resetting state values.
                    console.log("client_update_event_log => returns : ", serviceResult);

                    if (serviceResult.state === true) {
                        //
                        alert("Evènement modifié !")

                        //Clear form
                        if (!globals.parameters.debugging)
                            this.clearForm();

                        //Return to table mode
                        //Trigger on terminate
                        if (this.props.onTerminate){
                            this.props.onTerminate()
                        }                            
                    }
                    else{
                        alert("Erreur:" + serviceResult.message);
                    }
                    //--------------------------------------- End of promise Accept
                  } 
                )
        } catch(err){
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }
    }  

    componentDidMount = () =>{
        this.handle_media_filename = this.handle_media_filename.bind(this);
        this.handle_title = this.handle_title.bind(this);   
        this.handle_text = this.handle_text.bind(this);
        this.handle_text_rich_edit = this.handle_text_rich_edit.bind(this);

        //First component initialization
        if (globals.parameters.debugging){
            this.setState(
                {   
                    date:"10/07/2022",
                    day:10,
                    month:7,
                    year:2022,
                    media_filename:null, 
                    title:"Prédication de Patrick",                 
                    text:"Ayez du sel en vous",
                    
                    did_not_change:true, 
                    is_podcast:true,
                    
                });
        }

        //Component initialization in edit mode
        if (this.props.data){
            this.clearForm();
        }
    }

    validateField = (value) => {
        let fieldValidationErrors = this.state.formErrors;
        let dateValid = this.state.dateValid;
      
        //dateValid = value.match(/^(0|(\\+33)|(0033))[1-9][0-9]{8}$/i);
        dateValid = (value != "");
        fieldValidationErrors.date = dateValid ? '' : ' est invalide';
  
        this.setState({formErrors: fieldValidationErrors,
                        dateValid: dateValid
                      }, this.validateForm);
    }

    convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return {asString:[pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/'), asDate:d}
    }
    
    handle_date_picker = (pDateString) => {
        //Convert date picker into string
        let dateConvertedObject = this.convertDate(pDateString)

        //Update date value from form field
        this.setState(
            {
            did_not_change:false, 
            date:dateConvertedObject.asString,
            month:1 + dateConvertedObject.asDate.getMonth(), 
            day: dateConvertedObject.asDate.getDate(),
            year: dateConvertedObject.asDate.getFullYear(),
            //obsolete date_raw:pDateString //Date as raw string
            }, () => {this.validateField(dateConvertedObject.asString); console.clear(); console.log(`day:${this.state.day} / month:${this.state.month} / year:${this.state.year}`)});
    }

    handle_title = (e) => {
        e.preventDefault();
        //Update title value from form field
        this.setState({did_not_change:false, title:e.target.value}, () => {this.checkValidation(e.target.name);});
    }

    

    handle_text = (e) => {
        e.preventDefault();
        //Update text value from form field
        this.setState({did_not_change:false, text:e.target.value}, () => {this.checkValidation(e.target.name);});
    }

    handle_text_rich_edit = (new_rich_edit) => {
        //Update text value from form field
        this.setState({did_not_change:false, text:new_rich_edit}, () => {this.checkValidation("text");});
    }

    handle_media_filename = (UploadFile) => {
        this.setState(
            {did_not_change:false, 
              media_filename: UploadFile ? UploadFile.name : ""},
               ()=>{
                  console.log("handle_media_filename::this.state:", this.state);
                  if (UploadFile) {
                    console.log("UploadFile =>", UploadFile)
                    alert(`Let us copy ${UploadFile.name} file on remote server...`);
                  }
                });
    }

    onChangeCheckboxIsPodcast = (e) => {
        this.setState({is_podcast:e.target.checked?1:0}, ()=>{if(globals.parameters.debugging) console.log("checkvalidation not required for IsPodcast")});
    } 
    
    onClickCheckboxIsPodcast = (e) => {
        e.preventDefault()
        this.setState(
            {rgis_podcastpd:!this.ispodcastCheckBoxRef.current.checked?1:0}, 
            ()=>{if(globals.parameters.debugging) console.log("checkvalidation not required for IsPodcast")});
    }


    //Validation methods
    errorClass = (error) => {
        return(error.length === 0 ? '' : 'has-error');
    }

    checkValidation = (fieldName) => {
        let value = null;
        try{
            value = this.state[fieldName];
        }
        catch(e){
            alert(`Field '${fieldName}' not found`)
        }
        
        if (globals.parameters.debugging)
            console.log("fieldName:", fieldName, " value:", value)

        let fieldValidationErrors = this.state.formErrors;

        switch(fieldName) {
            case 'date':
                console.log("date checked ->", value)
                fieldValidationErrors.date = (value.length > 1) ? '' : ' est obligatoire';
                break;

            case 'media_filename':
                /*fieldValidationErrors.media_filename = (value.length > 0) ? '' : ' est obligatoire';*/
                break;

            case 'title':
                fieldValidationErrors.title = (value.length > 1) ? '' : ' est obligatoire';
                break;

            
            case 'text':
                fieldValidationErrors.text = (value.length > "<p><br></p>".length)? '' : ' est trop court';
                break;

            default:
                alert(`checkValidation field' ${fieldName}' not found !`)
                break;
        }

        if (globals.parameters.debugging){
            console.log(`-----------------`)
            console.log(`fieldName => ${fieldName}`)
            console.log(`value => ${value}`)
            console.log(`fieldValidationErrors => ${fieldValidationErrors}`)
            console.log(`fieldValidationErrors.join('') => ${Object.values(fieldValidationErrors).join("/")}`)
            console.log(`fieldValidationErrors.join('').length => ${Object.values(fieldValidationErrors).join("").length}`)
            /*
            console.log(`errorsFieldName => ${errorsFieldName}`)
            console.log(`nextErrors => ${nextErrors}`)
            console.log(`nextErrors.length => ${nextErrors.length}`)*/
        }

        //update internal state
        //this.setState({formErrors: fieldValidationErrors}, () => {if (globals.parameters.debugging) console.log("formErrors =", this.state.formErrors)});
        this.setState({formErrors: fieldValidationErrors});

        return (Object.values(fieldValidationErrors).join("").length === 0);
    }

    onCheckFormValidationBeforeSubmit = () => {
        //Force all fields check
        try{
            //let canSubmit = this.checkValidation('media_filename')
            let canSubmit = this.checkValidation('date')
            canSubmit &= this.checkValidation('title')          
            canSubmit &= this.checkValidation('text')

            return (canSubmit);
        }
        catch(e){
            return false;
        }
    }

    render = () => {
        return(
            <div id="partner-form-container">
                {globals.parameters.debugging && <h1>DEBUGGING MODE</h1>}
                <ModalBody>
                <form id="requete-form" onSubmit={this.props.data?this.onFormUpdateHandler:this.onFormSubmitHandler}> 
                <h2>Nouvel évènement</h2>
                <div className={`form-group`}/>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="col-md-12 col-form-label">Date:</label>
                            <div className="col-md-10">
                                <DatePicker 
                                locale="fr"
                                //selected={JSON.parse(this.state.date)} 
                                //selected={new Date()}
                                value ={this.state.date} 
                                placeholder="dd-mm-yyyy"
                                onChange={(pDateString) => {this.handle_date_picker(pDateString)}} />
                                {/* Sticky error label */}
                                <label
                                    className="form-error">
                                    {this.state.formErrors.date}
                                </label>
                            </div>
                        </div>

                        {/** Unused
                        <div className="col-md-12">
                            <label className="col-md-12 col-form-label">Contient un fichier</label>
                            <div className="col-md-12">
                                <input 
                                    className='pointer'
                                    ref={this.ispodcastCheckBoxRef}
                                    id="ispodcast" 
                                    type="checkbox" 
                                    checked={this.state.is_podcast===1?true:false} 
                                    onChange={(e)=>{this.onChangeCheckboxIsPodcast(e)}}/>
                                    
                                 <label 
                                    className="form-error">
                                    {this.state.formErrors.is_podcast} 
                                </label>
                            </div>
                        </div>*/}

                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Titre:</label>
                            <div className="col-md-10">
                                <input name="title" className="form-control" value={this.state.title} onChange={(e)=>{this.handle_title(e);}} type="text" placeholder="Titre de l'évènement"/>

                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.title} 
                                </label>                            
                            </div>

                        </div>

                        <div className="col-md-4">
                            <label className="col-md-12 col-form-label">Média (Audio ou Vidéo):</label>
                            <div className="col-md-10">
                                <MediaUpload
                                    data={this.state.media_filename} 
                                    uploadMedia={this.handle_media_filename} 
                                />
                                {/** 
                                <Blob3 
                                    Caption="media_filename ..." 
                                    data={this.state.media_filename} 
                                    uploadPicture={this.handle_media_filename} 
                                    pictureStyle="pic"
                                    buttonStyle = {"btn btn-secondary"}
                                    />*/}

                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.media_filename} 
                                </label>
                            </div>
                        </div>


                        
                    </div>

                    <div className="form-group row">
                        <div className="col-md-8">
                            <label className="col-md-12 col-form-label">Description activite:</label>
                            <div className="col-md-12 editor-container">

                                {!globals.parameters.rich_edit_enabled &&
                                <textarea name="text" className="form-control" value={this.state.text} onChange={(e)=>{this.handle_text(e);}} type="text" placeholder="Décrivez votre activité"/>}
                                
                                {/* Sticky error label */}
                                <label 
                                    className="form-error">
                                    {this.state.formErrors.text} 
                                </label>
                                {globals.parameters.rich_edit_enabled &&
                                <Editor
                                    value={this.state.text}
                                    onChange={(value) => {this.handle_text_rich_edit(value)}}
                                    readOnly={false}
                                    style={{ height: "150px", margin: "0em", flex: "1" }}
                                />}
                            </div>
                        </div>

                        
                    </div>
                    
                </form>
                </ModalBody>
                <ModalFooter>
                    {globals.parameters.debugging && <h1>DEBUGGING MODE</h1>}

                    <Button 
                        color="primary" 
                        form="requete-form" 
                        >{this.props.data?"Enregistrer les modifications":"Enregistrer"}</Button>

                    <Button 
                        color="secondary" 
                        onClick={(e) => {this.clearForm();}} 
                        hidden={this.state.did_not_change}>{this.props.data?"Annuler les modifications":"Tout effacer"}                        
                    </Button>

                    {this.props.onClose &&
                        <Button 
                            color="secondary"
                            hidden={!this.state.did_not_change} 
                            onClick={(e) => {this.props.onClose();}}>Fermer</Button>}
                </ModalFooter>
            </div>
        )
    }
}

export default EventForm;